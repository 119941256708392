import React from "react";
import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import Connector from "../../../redux/connector";
import {GetUserToken} from "../../../api/token";
import {PostToPbx} from "../../../api/http-post";
import {fetchDataEntity} from "../../../api/entity-data";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, Col, Row} from "antd";
import GenericComboBox from "../../../components/General/GenericComboBox";
import LoadingProcessing from "../../../components/Common/page/Common/LoadingProcessing";


const nodes = [
    {
        value: 'HOME',
        label: 'HOME',
        children: [
            {
                value: 'HOME2',
                label: 'HOME',
                children: [
                    {
                        label: "GLOBAL DASHBOARD",
                        value: "home-home-global-dashboard"
                    },
                    {
                        label: "OVERVIEW",
                        value: "home-overview-index"
                    },
                ]
            }
        ]
    },
    {
        label: "PAYROLL MINDER",
        value: "PAYROLL MINDER",
        children: [
            {
                label: "DASHBOARDS",
                value: "DASHBOARDS",
                children: [
                    {
                        label: "CONTRACT DASHBOARD",
                        value: "coming-soon-wage-payroll-dashboards-contract"
                    },
                    {
                        label: "PAYROLL DASHBOARD",
                        value: "coming-soon-wage-payroll-dashboards-payroll"
                    },
                ]
            },
            {
                label: "EMPLOYEE DATABASE",
                value: "EMPLOYEE DATABASE",
                children: [
                    {
                        label: "MPLOYEE RECORDS",
                        value: "wagepayroll-employee-record"
                    },
                    {
                        label: "APPROVE EMPLOYEE ONBOARDING",
                        value: "wagepayroll-employee-onboarding"
                    },
                ]
            },
            {
                label: "TIME TRACKING",
                value: "TIME TRACKING",
                children: [
                    {
                        label: "HOURLY PUNCHING",
                        value: "wagepayroll-timetracking-hourlypunching"
                    },
                    {
                        label: "DAY PUNCHING",
                        value: "wagepayroll-timetracking-daypunching",
                    },
                    {
                        label: "DEDUCTIONS",
                        value: "wagepayroll-timetracking-deductions"
                    },
                    {
                        label: "PENSION PROVIDENT FUND",
                        value: "wagepayroll-timetracking-pension-provident-fund"
                    },
                    {
                        label: "APPROVE EMPLOYEE CLOCKING",
                        value: "wagepayroll-timetracking-approve-employee-clocking"
                    }
                ]
            },
            {
                label: "PAYROLL",
                value: "PAYROLL",
                children: [
                    {
                        label: "PAYROLL REPORT",
                        value: "wagepayroll-payroll-report"
                    },
                    {
                        label: "CLOCK SUMMARY",
                        value: "wagepayroll-payroll-clocksummary"
                    },
                    {
                        label: "PAYROLL PREVIEW",
                        value: "wagepayroll-payroll-payrollpreview"
                    },

                ]
            },
            {
                label: "PAYROLL ACTIONS",
                value: "PAYROLLACTIONS",
                children: [
                    {
                        label: "CLASSIC PMIS PAYROLL ACTIONS",
                        value: "wagepayroll-payroll-payrollactions",
                    },
                    {
                        label: "PRO PMIS PAYROLL ACTIONS",
                        value: "wagepayroll-payroll-payrollactions-pro",
                    },
                    {
                        label: "U19 FROM",
                        value: "wagepayroll-support-document-u19",
                    },
                    {
                        label: "UFILLING REPORT",
                        value: "wagepayroll-support-document-ufilling",
                    },
                    {
                        label: "EMP201 REPORT",
                        value: "wagepayroll-support-document-u201",
                    },
                ]
            },

            {
                label: "WAGE CALCULATION RULES",
                value: "WAGE CALCULATION RULES",
                children: [
                    {
                        label: "ATTRIBUTE MAPPING",
                        value: "wagepayroll-calculationrules-attribute-mapping"
                    },
                    {
                        label: "SET OVERTIME RULES",
                        value: "wagepayroll-calculationrules-overtime-rules"
                    },
                    {
                        label: "SET PUBLIC HOLIDAYS RULES",
                        value: "wagepayroll-calculationrules-public-holiday-rules"
                    }
                ]
            },
            {
                label: "COMPLIANCE",
                value: "COMPLIANCE",
                children: [
                    {
                        label: "RECORD MANAGEMENT SET UP",
                        value: "wagepayroll-compliance-record-management"
                    },
                    {
                        label: "THRESHOLDS",
                        value: "wagepayroll-compliance-thresholds"
                    },
                    {
                        label: "APPROVE UPLOADED RECORDS",
                        value: "wagepayroll-compliance-approve-uploaded-records"
                    }
                ]
            },
            {
                label: "DOCUMENT CONFIGURATION",
                value: "DOCUMENT CONFIGURATION",
                children: [
                    {
                        label: "FNB CSV SETUP",
                        value: "wagepayroll-configure-bank-csv-fnb"
                    },
                    {
                        label: "ABSA CSV SETUP",
                        value: "wagepayroll-configure-bank-csv-absa"
                    },
                    {
                        label: "CAPITEC CSV SETUP",
                        value: "wagepayroll-configure-bank-csv-capetic"
                    },
                    {
                        label: "NEDBANK CSV SETUP",
                        value: "wagepayroll-configure-bank-csv-ndbank"
                    },
                    {
                        label: "STARNDARD BANK CSV SETUP",
                        value: "wagepayroll-configure-bank-csv-std"
                    },
                    {
                        label: "U19 FORM SETUP",
                        value: "wagepayroll-configure-u19-setup-info"
                    },
                    {
                        label: "EMP201 SETUP",
                        value: "wagepayroll-configure-u201-setup-info"
                    },
                    {
                        label: "LABOUR REPORT AREA",
                        value: "wagepayroll-configure-area"
                    },
                    {
                        label: "LABOUR REPORT SITES",
                        value: "wagepayroll-configure-area-site"
                    },
                    {
                        label: "SETUP LABOUR COVER HEADING",
                        value: "nvoicemanagement-configure-set-labour-cover"
                    },
                    {
                        label: "PAYSLIP HEADING SETUP",
                        value: "wagepayroll-configure-payslip-company-info"
                    },
                ]
            }
        ]
    },
    /** Module invoicing **/
    {
        label: "INVOICE MINDER",
        value: "INVOICE MINDER",
        children: [
            {
                label: "REPORTS",
                value: "REPORTS",
                children: [
                    {
                        label: "DASHBOARD",
                        value: "invoicemanagement-reports-dashboard"
                    },
                    {
                        label: "ACCOUNT RECEIVABLE",
                        value: "invoicemanagement-reports-account"
                    },
                    {
                        label: "DEBTOR ACCOUNT STATEMENT",
                        value: "invoicemanagement-reports-account-statement"
                    },
                    {
                        label: "INVOICE TRACKER",
                        value: "invoicemanagement-reports-tracker"
                    }
                ]
            },
            {
                label: "INVOICE GENERATORS",
                value: "INVOICE GENERATORS",
                children: [
                    {
                        label: "LABOUR INVOICE",
                        value: "invoicemanagement-generators-labour"
                    },
                    {
                        label: "SERVICE INVOICE",
                        value: "invoicemanagement-generators-service"
                    }, {
                        label: "KEY PERSONNEL INVOICE",
                        value: "invoicemanagement-generators-keypersonnel"
                    }, {
                        label: "VACCINATION INVOICE",
                        value: "invoicemanagement-generators-vaccination"
                    },

                ]
            },
            {
                label: "CONFIGURE INVOICE CONTROLS",
                value: "CONFIGURE INVOICE CONTROLS",
                children: [
                    {
                        label: "CREATE DEBTORS",
                        value: "invoicemanagement-configure-createdebtors"
                    },
                    {
                        label: "CREATE PROJECTS",
                        value: "invoicemanagement-configure-createprojects"
                    },
                    {
                        label: "SETUP PAYMENT TERMS",
                        value: "invoicemanagement-configure-setup-payment-terms"
                    },
                    {
                        label: "LOAD PURCHASE ORDERS",
                        value: "invoicemanagement-configure-load-purchase-orders"
                    },
                    {
                        label: "SET INVOICE SEQUENCE",
                        value: "invoicemanagement-configure-set-invoice-sequence"
                    },
                    {
                        label: "SETUP INTERNAL COMPANY",
                        value: "invoicemanagement-configure-set-virtual-company"
                    },
                    {
                        label: "SETUP INTERNAL COMPANY DEBTOR",
                        value: "invoicemanagement-configure-set-virtual-company-debtor"
                    },
                    {
                        label: "SET TAKE-ONBOARD BALANCER",
                        value: "invoicemanagement-configure-set-take-onboard-balance"
                    },
                    {
                        label: "LOAD ADHOC TRANSACTION",
                        value: "invoicemanagement-configure-load-transaction-adhoc"
                    },
                    {
                        label: "SETUP DEBTOR ACCOUNT STATEMENT",
                        value: "invoicemanagement-extra-config-debtor-account-statement"
                    },
                ]
            },
            {
                label: "SETUP SERVICES INVOICE",
                value: "SETUP SERVICES INVOICE",
                children: [
                    {
                        label: "DEFINE ITEM DESCRIPTION",
                        value: "invoicemanagement-setup-service-define-item"
                    },
                    {
                        label: "DEFINE INVOICE PERIODS",
                        value: "invoicemanagement-setup-service-define-periods"
                    },
                    {
                        label: "SETUP SITES TO BE SERVICED",
                        value: "invoicemanagement-setup-service-define-sites"
                    },
                    {
                        label: "SETUP COMPANY BANKING DETAIL",
                        value: "invoicemanagement-setup-service-define-banking"
                    },
                    {
                        label: "SETUP INVOICE HEADER",
                        value: "invoicemanagement-setup-service-define-header"
                    }
                ]
            },
            {
                label: "SETUP LABOUR INVOICE",
                value: "SETUP LABOUR INVOICE",
                children: [
                    {
                        label: "DEFINE ITEM DESCRIPTION",
                        value: "invoicemanagement-setup-labour-define-item",
                    },
                    {
                        label: "DEFINE INVOICE PERIODS",
                        value: "invoicemanagement-setup-labour-define-periods",
                    },
                    /*{
                        label: "SETUP SITES LISTING",
                        value: "invoicemanagement-setup-labour-define-sites",
                    },*/
                    {
                        label: "SETUP COMPANY BANKING DETAIL",
                        value: "invoicemanagement-setup-labour-define-banking",
                    },
                    {
                        label: "SETUP INVOICE HEADER",
                        value: "invoicemanagement-setup-labour-define-header",
                    },
                    {
                        label: "SET EMPLOYEE WORKING DAY",
                        value: "invoicemanagement-setup-labour-define-workings-days",
                    },
                    {
                        label: "SET EMPLOYEE DESIGNATIONS",
                        value: "invoicemanagement-setup-labour-define-designations",
                    },
                    {
                        label: "SET SITE EMPLOYEES",
                        value: "invoicemanagement-setup-labour-define-site-employees",
                    },
                    {
                        label: "SETUP KEY PERSONNEL",
                        value: "invoicemanagement-setup-labour-define-key-personnel",
                    },
                    {
                        label: "SETUP VACCINATION ITEMS",
                        value: "invoicemanagement-setup-labour-define-vaccination-items",
                    },
                    {
                        label: "SETUP SITES LISTING",
                        value: "labour-setting-site-listing",
                    },
                    {
                        label: "SETUP INVOICE TEMPLATE",
                        value: "labour-setting-invoice-template",
                    },


                ]
            },
            {
                label: "SERVICES MANAGEMENT",
                value: "SERVICES MANAGEMENT",
                children: [

                    {
                        label: "DAILY SERVICE LOGS",
                        value: "services-delivery-daily-logger"
                    },
                    {
                        label: "SERVICES FINANCIAL INDEX",
                        value: "services-logger-financial-tracker"
                    },
                    {
                        label: "COCT SERVICE SUMMARY",
                        value: "services-logger-coct-summary"
                    },
                    {
                        label: "SUBCONTRACTOR SERVICE PERFORMANCE",
                        value: "services-logger-subcontractor-performance"
                    },
                    {
                        label: "DELIVERY NOTE LOGGING",
                        value: "services-logger-entry-form"
                    },
                    {
                        label: "DELIVERY NOTE CSV IMPORT",
                        value: "services-logger-csv-import"
                    },

                    {
                        label: "SETUP VEHICLE",
                        value: "services-logs-vehicle"
                    },
                    {
                        label: "SETUP DRIVER",
                        value: "services-logs-driver"
                    },
                    {
                        label: "SETUP SUB CONTRACTOR",
                        value: "services-logs-contractor"
                    },
                    {
                        label: "SETUP ALLOCATE CONTRACTOR VEHICLES",
                        value: "services-logs-contractor-vehicle"
                    },
                ],
            },
            {
                label: "LABOUR MANAGEMENT",
                value: "LABOUR MANAGEMENT",
                children: [
                    {
                        label: "DAILY LOGGING",
                        value: "labour-logging-daily-logs"
                    },
                    {
                        label: "IMPORT FROM CSV FILE",
                        value: "labour-logging-import-csv"
                    },
                    {
                        label: "IMPORT FROM CLOCKS",
                        value: "labour-logging-import-clocks"
                    },
                    {
                        label: "CoCT MONTHLY LOGS",
                        value: "labour-logging-coct-monthly-report"
                    },
                ]
            },
        ]
    },
    {
        label: "QUOTATION  MINDER",
        value: "QUOTATION MINDER",
        children: [
            {
                label: "Settings",
                value: "Settings",
                children: [
                    {
                        label: "SETUP LABOUR SITE LISTING",
                        value: "quotation-setting-labour-site-listing"
                    },
                    {
                        label: "SETUP LABOUR DESIGNATION RATE ",
                        value: "quotation-setting-labour-designation-rate",
                    },
                    {
                        label: "SETUP SERVICE SITE LISTING",
                        value: "quotation-setting-service-site-listing",
                    },
                    {
                        label: "SETUP COMPANY HEADER LABOUR",
                        value: "quotation-setting-company-header-labour",
                    },
                    {
                        label: "SETUP COMPANY HEADER SERVICE",
                        value: "quotation-setting-company-header-service",
                    },
                    {
                        label: "SETUP COMPANY QUOTE NUMBER PREFIX",
                        value: "quotation-setting-company-inv-prefix",
                    },
                    {
                        label: "SETUP COMPANY SIGNATURE",
                        value: "quotation-setting-company-signature",
                    },
                    {
                        label: "LOAD VACCINATION TOOLS",
                        value: "quotation-setting-vaccination-tools",
                    },
                    {
                        label: "SETUP LABOUR KEY PERSONNEL",
                        value: "quotation-setting-labour-keypersonnel",
                    },
                    {
                        label: "SETUP LABOUR WORKING HOUR",
                        value: "quotation-setting-labour-workinghours",
                    },
                    {
                        label: "LOAD WATER TANKER",
                        value: "quotation-setting-water-tanker",
                    },
                ]
            },
            {
                label: "Generate",
                value: "Generate",
                children: [
                    {
                        label: "COCT PLANNED LABOUR",
                        value: "quotation-generate-coct-labour",
                    },
                    {
                        label: "COCT PLANNED SERVICE ",
                        value: "quotation-generate-coct-service",
                    },
                    {
                        label: "COCT WATER TANKER",
                        value: "quotation-generate-coct-water-tanker",
                    },
                    {
                        label: "COCT KEY PERSONNEL",
                        value: "quotation-generate-coct-key-personnel",
                    },
                    {
                        label: "COCT VACCINATION",
                        value: "quotation-generate-coct-vaccination",
                    },
                ],
            },
            {
                label: "Report",
                value: "Report",
                children: [
                    {
                        label: "QUOTE TRACKER",
                        value: "quotation-report-tracker",
                    },
                ],
            }
        ]
    },
    {
        label: "SETTINGS",
        value: "SETTINGS",
        children: [
            {
                label: "ONBOARD NEW COMPANY",
                value: "ONBOARD NEW COMPANY",
                children: [
                    {
                        label: "COMPANY NAME",
                        value: "settings-onboard-company-name"
                    },
                    {
                        label: "SET COMPANY FINANCIAL YEAR",
                        value: "settings-onboard-company-financial-year"
                    }
                ]
            },
            {
                label: "NEW PROJECT SETUP",
                value: "NEW PROJECT SETUP",
                children: [
                    {
                        label: "PROJECT NAMING",
                        value: "settings-new-project-naming"
                    },
                    {
                        label: "SETUP DATABASE FIELD STRUCTURE",
                        value: "settings-new-project-database-field"
                    },
                    {
                        label: "SETUP EMPLOYEE CODE PREFIX",
                        value: "settings-new-project-employee-code-prefix"
                    },
                    {
                        label: "EDIT DATABASE FIELD STRUCTURE",
                        value: "settings-new-project-edit-database-field-structure"
                    },
                    {
                        label: "CONTRACT DETAIL SETUP",
                        value: "settings-new-project-contract-detail"
                    },
                ]
            },
            {
                label: "UPLOADING",
                value: "UPLOADING",
                children: [
                    {
                        label: "IMPORT EMPLOYEE RECORDS",
                        value: "settings-uploading-employee-records",
                    },
                    {
                        label: "BULK CLOCK EMPLOYEES",
                        value: "settings-uploading-employee-clocks",
                    },
                ]
            },
            {
                label: "BILLING",
                value: "BILLING",
                children: [
                    {
                        label: "ACTIVE ACCOUNTS",
                        value: "settings-billing-active-accounts"
                    },
                    {
                        label: "SUSPENDED ACCOUNTS",
                        value: "settings-billing-suspended-accounts"
                    },
                    {
                        label: "ACTIVATE ACCOUNTS",
                        value: "settings-billing-activate-accounts"
                    },
                    {
                        label: "PAYMENT ALERT",
                        value: "settings-billing-payment-alert"
                    },
                ]
            },
            {
                label: "MANAGE ROLES & ACCESS",
                value: "MANAGE ROLES & ACCESS",
                children: [
                    {
                        label: "DEFINE USER ROLE",
                        value: "settings-manage-access-define-user-role"
                    },
                    {
                        label: "SETUP ACCESS RULES",
                        value: "settings-manage-access-setup-access-Rules"
                    },
                    {
                        label: "SETUP LOGIN CREDENTIAL ATTRIBUTES",
                        value: "settings-manage-access-define-credential-attributes"
                    },
                    {
                        label: "PERMISSIONS & USER ROLE ACCESS",
                        value: "settings-manage-access-permission-user-role-access"
                    },
                    {
                        label: "SETUP ADMIN MOBILE USER",
                        value: "settings-manage-access-setup-admin-mobile-user"
                    },
                    {
                        label: "SETUP PMIS2 MOBILE BULK USER",
                        value: "settings-manage-access-setup-pmis2-mobile-bullk-user"
                    },
                    {
                        label: "SETUP EXECUTIVE MOBILE USER",
                        value: "settings-manage-access-setup-executive-mobile-user"
                    },
                ]
            },
            {
                label: "UTILITIES",
                value: "UTILITIES",
                children: [
                    {
                        label: "SET PUBLIC HOLIDAYS",
                        value: "settings-utilities-set-public-holidays",
                    }
                ]
            }
        ]
    }
]


class SuperAccessLevelPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Companies: [],
            Roles: [],
            Access: [],
            isLoading: false,
            checked: [],
            expanded: [],
            selectedCompany: "",
            selectedRole: "",
        }
    }

    componentDidMount = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await this.loadData("roles", "Roles");
        await this.loadData("AccessLevelMenu", "Access")
    }
    loadData = async (targetEntity, stateKey) => {
        let _this = this;
        let userInfo = GetUserToken();
        let table = targetEntity;
        let hub = {};
        hub.Org = userInfo.Org;
        let post = {}
        post.AutoGenerate = false;
        post.HasUniqueKey = false;
        post.AutoGenerateField = "";
        post.Data = {};
        post.Params = {}
        post.Entity = table;
        let endpoint = "/common/entity/" + table + "/list";
        this.setState({
            isLoading: true,
        })

        await PostToPbx(post, endpoint, async (data) => {
            console.log("SettingProductPage loadInitialData response > ", post, " > ", data);
            await _this.setState({
                [stateKey]: data.RESULT,
                isLoading: false,
            })
        });
    }

    handleChangeCompany = async (val, key) => {
        let ls = [];
        if (key === "selectedRole") {
            for (let i in this.state.Access) {
                let row = this.state.Access[i];
                if (row.org === this.state.selectedCompany
                    && row.role === val) {
                    ls.push(row.link)
                }
            }
        }
        this.setState({
            [key]: val,
            checked: ls
        });
    }
    onSubmitNewRoles = async () => {
        if (this.state.selectedCompany === "") {
            return alert("Sorry please select your organization!")
        }
        if (this.state.selectedRole === "") {
            return alert("Sorry please select your role!")
        }
        if (!window.confirm("Do you want to submit this change?")) {
            return
        }
        let _this = this;
        let ls = [];
        for (let i in this.state.checked) {
            let link = this.state.checked[i];
            ls.push({
                org: this.state.selectedCompany,
                role: this.state.selectedRole,
                link: link,
            })
        }

        let endpoint = "/access/control/new";
        this.setState({
            isLoading: true,
        })

        let post = {
            org: this.state.selectedCompany,
            role: this.state.selectedRole,
            Data: ls
        }

        await PostToPbx(post, endpoint, async (data) => {
            console.log("onSubmitNewRoles response > ", post, " > ", data);
            await _this.loadData("AccessLevelMenu", "Access")
            this.setState({
                isLoading: true,
            })
            alert("Thank you record submitted!");
        });


    }
    getChecked = () => {
        let ls = [];
        for (let i in this.state.Access) {
            let row = this.state.Access[i];
            console.log(`iiiiiii 1 > ${row.org} == ${this.state.selectedCompany} > ${row.org === this.state.selectedCompany}`)
            console.log(`iiiiiii 2 > ${row.role} == ${this.state.selectedRole} > ${row.role === this.state.selectedRole}`)
            if (row.org === this.state.selectedCompany && row.role === this.state.selectedRole) {
                ls.push(row.link)
            }
        }
        console.log("Company list > ", ls, " > ", this.state.Access)
    }
    getRoleList = () => {
        let ls = [];
        for (let i in this.state.Roles) {
            let row = this.state.Roles[i];
            if (row.org === this.state.selectedCompany) {
                ls.push({
                    key: row.name,
                    val: row.name
                });
            }
        }
        return ls;
    }
    getCompanyList = () => {
        let ls = [];
        for (let i in this.state.Companies) {
            let row = this.state.Companies[i];
            ls.push({
                key: row.CustomerNumber,
                val: row.Name,
            });
        }

        return ls;
    }

    renderCompaniesSelection = () => {


        return (
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"list"}
                data={this.state.Companies}
                label={"Select Organization"}
                name={"selectedCompany"}
                message={"Please select your organization!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedCompany}
                recordOptionKey={"CustomerNumber"}
                recordOptionValue={"CustomerNumber"}
                recordOptionDisplay={"Name"}
                noCondition={true}
            />
        )
    }
    renderRoleSelection = () => {
        let ls = [];
        for (let i in this.state.Roles) {
            let row = this.state.Roles[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            ls.push(row)
        }
        return (
            <GenericComboBox
                displayConditionStateKey={this.state.component}
                displayConditionStateValue={"list"}
                data={ls}
                label={"Select Role"}
                name={"selectedRole"}
                message={"Please select your role!"}
                handleChange={this.handleChangeCompany}
                getValue={this.state.selectedRole}
                recordOptionKey={"name"}
                recordOptionValue={"name"}
                recordOptionDisplay={"name"}
                noCondition={true}
            />
        )
    }

    render() {
        console.log(":*: this.state.checked > ", this.state.checked);
        console.log(":*: this.state.expanded > ", this.state.expanded);
        const companyOptions = this.getCompanyList();
        const roleOptions = this.getRoleList()

        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Setup Company Role Access Menu</Title>
                    <div style={{minWidth: "100%", padding: 10}}>

                        <Row style={{marginTop: 20,}}>
                            <Col span={24}>
                                <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                    {this.renderCompaniesSelection()}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div style={{float: "left", minWidth: "100%", maxWidth: "100%"}}>
                                    {this.renderRoleSelection()}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <CheckboxTree
                                    nodes={nodes}
                                    checked={this.state.checked}
                                    expanded={this.state.expanded}
                                    onCheck={checked => this.setState({checked})}
                                    onExpand={expanded => this.setState({expanded})}

                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 30}}>
                            <Col span={24}>
                                <Button
                                    type={"primary"}
                                    onClick={this.onSubmitNewRoles}
                                >Save Change</Button>
                            </Col>
                        </Row>

                    </div>
                </div>
                <LoadingProcessing open={this.state.isLoading}/>
            </Page>
        )
    }

}


export default Connector(SuperAccessLevelPage);

const styles = {
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

