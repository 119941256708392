import React from 'react';
import {Router, Route, Switch} from 'react-router';
import {Provider} from 'react-redux';
import logo from './logo.svg';
import './App.css';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import store from "./redux/store";
import NotFoundPage from "./pages/web/Common/404";
import WelcomePage from "./pages/web/Welcome";
import PrivacyPolicy from "./pages/web/PrivacyPolicy";
import LoginPage from "./pages/web/Common/LoginPage";
import denied from "./pages/web/Common/denied";
import DatabaseSettingEntityPage from "./pages/web/Database/DatabaseSettingEntityPage";
import DatabaseSettingAttributePage from "./pages/web/Database/DatabaseSettingAttributePage";
import DatabaseSettingRecordPage from "./pages/web/Database/DatabaseSettingRecordPage";
import DatabaseSettingContractPage from "./pages/web/Database/DatabaseSettingContractPage";
import HomeOverviewIndexPage from "./pages/web/Home/HomeOverviewIndexPage";
import SuperCompanyOnboardPage from "./pages/web/Super/SuperCompanyOnboardPage";
import SuperCompanyActivePage from "./pages/web/Super/SuperCompanyActivePage";
import DatabaseImportEmployeeCsvPage from "./pages/web/Database/DatabaseImportEmployeeCsvPage";
import ReportPaymentCyclePage from "./pages/web/Report/ReportPaymentCycleDaysPage";
import ClockingBulkPage from "./pages/web/Clocking/ClockingBulkPage";
import QaClockingPage from "./pages/web/Clocking/QaClockingPage";
import MobileLoginSetupPage from "./pages/web/Users/MobileLoginSetupPage";
import SettingUsersManagePage from "./pages/web/Settings/Users/SettingUsersManagePage";
import MobileBulkClockUserPage from "./pages/web/Users/MobileBulkClockUserPage";
import DatabaseQaOnboardingPage from "./pages/web/Database/DatabaseQaOnboardingPage";
import DeductionEmployeeRegister from "./pages/web/Deductions/DeductionEmployeeRegister";
import ReportPayrollUploadBulkCsv from "./pages/web/Report/ReportPayrollUploadBulkCsv";

import ComplianceSetupRequiredPage from "./pages/web/Compliance/ComplianceSetupRequiredPage";
import ComplianceRatioPage from "./pages/web/Compliance/ComplianceRatioPage";
import SuperPublicHolidayPage from "./pages/web/Super/SuperPublicHolidayPage";
import DatabaseSettingAttributePage2 from "./pages/web/Database/DatabaseSettingAttributePage2";
import ComplianceQaPage from "./pages/web/Compliance/ComplianceQaPage";
import ReportPaymentCycleHoursPage from "./pages/web/Report/ReportPaymentCycleHoursPage";
import SettingWageOvertimePage from "./pages/web/Settings/Wages/SetttingWageOvertimePage";
import SettingWagePublicHolidayPage from "./pages/web/Settings/Wages/SettingWagePublicHolidayPage";
import SettingWageAttributeMappingPage from "./pages/web/Settings/Wages/SettingWageAttributeMappingPage";
import DatabaseSettingEmployeeCodeRulesPage from "./pages/web/Database/DatabaseSettingEmployeeCodeRulesPage";
import ReportPayrollClockingSummaryPage from "./pages/web/Report/ReportPayrollClockingSummaryPage";
import InvoiceLabourAllPage from "./pages/web/invoices/InvoiceLabourAll";
import SettingInvoiceLabourPurchaseNumber from "./pages/web/Settings/Invoice/SettingInvoiceLabourPurchaseNumberPage";
import SettingInvoiceLabourHeadingPage from "./pages/web/Settings/Invoice/SettingInvoiceLabourHeadingPage";
import SettingInvoiceLabourVaccinationPage from "./pages/web/Settings/Invoice/SettingInvoiceLabourVaccinationPage";
import InvoiceSetupSitePage from "./pages/web/invoices/SetupInvoiceService/InvoiceSetupSitePage";
import InvoiceSetupPeriodPage from "./pages/web/invoices/SetupInvoiceService/InvoiceSetupPeriodPage";
import InvoiceSetupBankingPage from "./pages/web/invoices/SetupInvoiceService/InvoiceSetupBankingPage";
import InvoiceSetupHeaderPage from "./pages/web/invoices/SetupInvoiceService/InvoiceSetupHeaderPage";
import InvoiceSetupDebtorPage from "./pages/web/invoices/Manage/ManageInvoiceDebtorPage";
import InvoiceSetupItemPage from "./pages/web/invoices/SetupInvoiceService/InvoiceSetupItemPage";
import ManagePurchaseOrderPage from "./pages/web/invoices/Manage/ManagePurchaseOrderPage";
import ManageInvoiceSequencePage from "./pages/web/invoices/Manage/ManageInvoiceSequencePage";
import InvoiceDraftServicePage from "./pages/web/invoices/DraftInvoice/InvoiceDraftServicesPage";
import InvoiceSetupTermsPage from "./pages/web/invoices/SetupInvoiceService/InvoiceSetupTermsPage";
import InvoiceReportTrackerPage from "./pages/web/invoices/Report/InvoiceReportTrackerPage";
import InvoiceReportAccountReceivablePage from "./pages/web/invoices/Report/InvoiceReportAccountReceivablePage";
import InvoiceDraftLabourPage from "./pages/web/invoices/DraftInvoice/InvoiceDraftLabourPage";
import ManageInvoiceProjectPage from "./pages/web/invoices/Manage/ManageInvoiceProjectPage";
import SetupLabourInvoiceItemDescription
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceItemDescriptionPage";
import SetupLabourInvoiceItemPeriodPage from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceItemPeriodPage";
import SetupLabourInvoiceSiteListingPage
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceSiteListingPage";
import SetupLabourInvoiceItemSiteWorkingDayPage
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceStiteWorkingDayPage";
import SetupLabourInvoiceItemHeaderPage from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceItemHeaderPage";
import SetupLabourInvoiceItemKeyPersonnelPage
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceItemKeyPersonnelPage";
import SetupLabourInvoiceItemVaccinationPage
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceItemVaccinationPage";
import SetupLabourInvoiceDesignationsPage
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceDesignationsPage";
import SetupLabourInvoiceSiteDesignationsPage
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceSiteDesigantionPage";
import InvoiceDashboardPage from "./pages/web/invoices/Dashboard/InvoiceDashboardPage";
import SetupLabourInvoiceItemBankingDetailPage
    from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceItemBankingDetailPage";
import SuperAccessLevelPage from "./pages/web/Super/SuperAccessLevelPage";
import SuperUserRolePage from "./pages/web/Super/SuperUserRolePage";
import {LoadConfigInfo} from "./configures/serve";
import DayClockingPunchingPage from "./pages/web/Clocking/DayClockingPunchingPage";
import MobileProRegisterUserPage from "./pages/web/MobilePro/MobileProRegisterUserPage";
import ManageInvoiceVirtualCompanyPage from "./pages/web/invoices/Manage/ManageInvoiceVirtualCompanyPage";
import ManageInvoiceVirtualCompanyDebtorPage from "./pages/web/invoices/Manage/MangageInvoiceVirtualCompanyDebtorPage";
import DashboardWagePayrollPage from "./pages/web/Report/DashboardWagePayrollContractPage";
import IntegrationPastelFieldAttributePage from "./pages/web/IntegrationApi/Pastel/IntegrationPastelFieldAttributePage";
import IntegrationPastelProjectPage from "./pages/web/IntegrationApi/Pastel/IntegrationPastelProjectPage";
import IntegrationPastelPayPeriodPage from "./pages/web/IntegrationApi/Pastel/IntegrationPastelPayPeriodPage";
import SettingExtraConfigureAreaPage from "./pages/web/Settings/ExtraConfigures/SettingExtraConfigureAreaPage";
import ManageInvoiceTakeOnboardBalancePage from "./pages/web/invoices/Manage/ManageInvoiceTakeOnboardBalancePage";
import ManageInvoiceTransactionAdhocPage from "./pages/web/invoices/Manage/ManageInvoiceTransactionAdhocPage";
import SettingExtraConfigureAreaSitePage from "./pages/web/Settings/ExtraConfigures/SettingExtraConfigureAreaSitePage";
import SettingExtraConfigureBankCsvFnbPage
    from "./pages/web/Settings/ExtraConfigures/SettingExtraConfigureBankCsvFnbPage";
import PayslipDemo from "./pages/web/Report/PayslipDemo";
import ManageInvoiceLabourReportSettingPage from "./pages/web/invoices/Manage/ManageInvoiceLabourReportSettingPage";
import PayrollSupportingDocumentU19Page from "./pages/web/PayrollSupportingDocument/PayrollSupportingDocumentU19Page";
import SettingExtraConfigureU19Page from "./pages/web/Settings/ExtraConfigures/SettingExtraConfigureU19Page";
import SettingExtraConfigurePayslipCompanyInfoPage
    from "./pages/web/Settings/ExtraConfigures/SettingExtraConfigurePayslipPage";
import InvoiceAccountStatementPage from "./pages/web/invoices/Report/InvoiceAccountStatementPage";
import InvoiceExtraSettingAccountStatementPage
    from "./pages/web/invoices/ExtraSetting/InvoiceExtraSettingAccountStatementPage";
import PayrollSupportingDocumentUFillingPage
    from "./pages/web/PayrollSupportingDocument/PayrollSupportingDocumentUFillingPage";
import PayrollSupportingDocumentU201Page from "./pages/web/PayrollSupportingDocument/PayrollSupportingDocumentU201Page";
import SettingExtraConfigureU201Page from "./pages/web/Settings/ExtraConfigures/SettingExtraConfigureU201Page";
import PayrollActionMasterFilePage from "./pages/web/PayrollActions/PayrollActionMasterFilePage";
import InvoiceExtraSettingSignaturePage from "./pages/web/invoices/ExtraSetting/InvoiceExtraSettingSignaturePage";
import ReportPayrollSavedStatusPage from "./pages/web/Report/ReportPayrollSavedStatusPage";
import DashboardPayrollProfitPage from "./pages/web/Dashboard/DashboardPayrollProfitPage";
import PayrollWageDefineRatePage from "./pages/web/PayrollRateSetup/PayrollWageDefineRatePage";
import PayrollWageDefineOvertimePage from "./pages/web/PayrollRateSetup/PayrollWageDefineOvertimePage";
import PayrollWageDefineStatutoryPage from "./pages/web/PayrollRateSetup/PayrollWageDefineStatutoryPage";
import PayrollWageDefineWorkingDaysPage from "./pages/web/PayrollRateSetup/PayrollWageDefineWorkingDaysPage";
import LabourPeriodPage from "./pages/web/Labour/LabourPeriodPage";
import LabourGenerateReportPage from "./pages/web/Labour/LabourGenerateReportPage";
import ServicesDeliveryDailyLoggerPage from "./pages/web/ServiceModule/DeliveryNotes/ServicesDeliveryDailyLoggerPage";
import ServiceLogsVehiclePage from "./pages/web/ServiceModule/DeliveryNotes/ServiceLogsVehiclePage";
import ServiceLogsDriverPage from "./pages/web/ServiceModule/DeliveryNotes/ServiceLogsDriverPage";
import PensionProvidentFundLoggerPage from "./pages/web/Deductions/PensionProvidentFundLoggerPage";
import ServiceLogsFinancialTrackerPage from "./pages/web/ServiceModule/DeliveryNotes/ServiceLogsFinancialTrackerPage";
import LabourLogsPage from "./pages/web/LabourModule/Logging/LabourLoggingDailyLogsPage";
import LabourLoggingDailyLogsPage from "./pages/web/LabourModule/Logging/LabourLoggingDailyLogsPage";
import LabourLoggingImportCsvPage from "./pages/web/LabourModule/Logging/LabourLoggingImportCsvPage";
import LabourLoggingSyncPage from "./pages/web/LabourModule/Logging/LabourLoggingSyncPage";
import LabourLoggingExportPage from "./pages/web/LabourModule/Logging/LabourLoggingExportPage";
import ServiceLogsFormPage from "./pages/web/ServiceModule/DeliveryNotes/ServiceLogsFormPage";
import ServiceContractorPage from "./pages/web/ServiceModule/Settings/ServiceContractorPage";
import ServiceContractorVehiclePage from "./pages/web/ServiceModule/Settings/ServiceContractorVehiclePage";
import ServiceLogsImportCsvPage from "./pages/web/ServiceModule/DeliveryNotes/ServiceLogsImportCsvPage";
import LabourLoggingExportTimesheetCsvPage from "./pages/web/LabourModule/Logging/LabourLoggingExportTimesheetCsvPage";
import ServiceLogsCoCtSummaryPage from "./pages/web/ServiceModule/DeliveryNotes/ServiceLogsCoctSummaryPage";
import LabourLoggingMonthlyCoCTPage from "./pages/web/LabourModule/Logging/LabourLoggingMonthlyCoCTPage";
import InvoiceDraftKeyPersonnelPage from "./pages/web/invoices/DraftInvoice/InvoiceDraftKeyPersonnelPage";
import InvoiceDraftVaccinationPage from "./pages/web/invoices/DraftInvoice/InvoiceDraftVaccinationPage";
import LabourSettingSiteListingPage from "./pages/web/invoices/SetupLabourInvoice/LabourSettingSiteListingPage";
import LabourSettingInvoiceTemplatePage from "./pages/web/invoices/SetupLabourInvoice/SetupLabourInvoiceTemplatePage";
import QuoteLabourPlannedSitePage from "./pages/web/Quotation/QuoteLabourPlannedSitePage";
import QuoteLabourPlannedRatePage from "./pages/web/Quotation/QuoteLabourPlannedRatePage";
import QuoteCompanyInvPrefixPage from "./pages/web/Quotation/QuoteCompanyInvPrefixPage";
import QuoteCompanyInvHeaderPage from "./pages/web/Quotation/QuoteCompanyInvHeaderLabourPage";
import QuoteServicePlannedSitePage from "./pages/web/Quotation/QuoteServicePlannedSitePage";
import QuoteCompanyInvSignaturePage from "./pages/web/Quotation/QuoteCompanyInvSignaturePage";
import QuoteVaccinationToolsPage from "./pages/web/Quotation/QuoteVaccinationToolsPage";
import QuoteKeyPersonalPage from "./pages/web/Quotation/QuoteKeyPersonalPage";
import QuoteWaterTankerPage from "./pages/web/Quotation/QuoteWaterTankerPage";
import QuoteGenerateLabourPlannedPage from "./pages/web/Quotation/QuoteGenerateLabourPlannedPage";
import QuoteReportTrackerPage from "./pages/web/Quotation/QuoteReportTrackerPage";
import QuoteLabourWorkingHoursPage from "./pages/web/Quotation/QuoteLabourWorkingHoursPage";
import QuoteGenerateServicePlannedPage from "./pages/web/Quotation/QuoteGenerateServicePlannedPage";
import QuoteCompanyInvHeaderServicePage from "./pages/web/Quotation/QuoteCompanyInvHeaderServicePage";
import QuoteGenerateWaterTankerPage from "./pages/web/Quotation/QuoteGenerateWaterTankerPage";
import QuoteGenerateKeyPersonnelPage from "./pages/web/Quotation/QuoteGenerateKeyPersonnelPage";
import QuoteGenerateVaccinationPage from "./pages/web/Quotation/QuoteGenerateVaccinationPage";
import InvoiceDraftSanitechPage from "./pages/web/invoices/DraftInvoice/InvoiceDraftSanitechPage";
import InvoiceDraftMorekiPage from "./pages/web/invoices/DraftInvoice/InvoiceDraftMorekiPage";
import InvoiceDradtBidvestPage from "./pages/web/invoices/DraftInvoice/InvoiceDradtBidvestPage";


const createHistory = require("history").createHashHistory;
const history = createHistory();

/*
<Route exact path="" component={}/>
            <Route exact path="" component={}/>
 */
function webRouter() {
    return (
        <Switch>
            <Route exact path="/login" component={LoginPage}/>
            <Route exact path="/" component={LoginPage}/>
            {/*****THIS IS THE BEGINNING OF THE NEW MENUS*****/}
            <Route exact path="/home-overview-index" component={HomeOverviewIndexPage}/>
            <Route exact path="/wagepayroll-employee-record" component={DatabaseSettingRecordPage}/>
            <Route exact path="/wagepayroll-employee-onboarding" component={DatabaseQaOnboardingPage}/>
            <Route exact path="/wagepayroll-timetracking-hourlypunching" component={ClockingBulkPage}/>
            <Route exact path="/wagepayroll-timetracking-daypunching" component={DayClockingPunchingPage}/>
            <Route exact path="/wagepayroll-timetracking-deductions" component={DeductionEmployeeRegister}/>
            <Route exact path="/wagepayroll-timetracking-pension-provident-fund"
                   component={PensionProvidentFundLoggerPage}/>


            <Route exact path={"/wagepayroll-timetracking-approve-employee-clocking"} component={QaClockingPage}/>
            <Route exact path={"/wagepayroll-payroll-report"} component={ReportPaymentCycleHoursPage}/>
            <Route exact path={"/wagepayroll-payroll-clocksummary"} component={ReportPayrollClockingSummaryPage}/>
            <Route exact path={"/wagepayroll-calculationrules-attribute-mapping"}
                   component={SettingWageAttributeMappingPage}/>
            <Route exact path={"/wagepayroll-calculationrules-overtime-rules"} component={SettingWageOvertimePage}/>
            <Route exact path={"/wagepayroll-calculationrules-public-holiday-rules"}
                   component={SettingWagePublicHolidayPage}/>

            <Route exact path={"/wagepayroll-support-document-u19"}
                   component={PayrollSupportingDocumentU19Page}/>
            <Route exact path={"/wagepayroll-support-document-ufilling"}
                   component={PayrollSupportingDocumentUFillingPage}/>
            <Route exact path={"/wagepayroll-support-document-u201"}
                   component={PayrollSupportingDocumentU201Page}/>

            <Route exact path={"/wagepayroll-configure-u19-setup-info"}
                   component={SettingExtraConfigureU19Page}/>
            <Route exact path={"/wagepayroll-configure-u201-setup-info"}
                   component={SettingExtraConfigureU201Page}/>
            <Route exact path={"/wagepayroll-configure-payslip-company-info"}
                   component={SettingExtraConfigurePayslipCompanyInfoPage}/>


            <Route exact path={"/wagepayroll-payroll-banking-integration"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-payrollactions-gateway-ntegration"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-payrollactions-pastel-ntegration"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-payrollactions-banking-payment-report"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-payrollactions-coct-payment-report"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-payrollactions-payroll-payslip"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-payrollactions-labour-report"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-payrollactions-timesheet-report"}
                   component={PayrollActionMasterFilePage}/>
            <Route exact path={"/wagepayroll-payroll-savedpayroll"} component={ReportPayrollSavedStatusPage}/>
            <Route exact path={"/wagepayroll-dashboard-payroll-Profitability"} component={DashboardPayrollProfitPage}/>


            <Route exact path={"/wagepayroll-compliance-record-management"} component={ComplianceSetupRequiredPage}/>
            <Route exact path={"/wagepayroll-compliance-thresholds"} component={ComplianceRatioPage}/>
            <Route exact path={"/wagepayroll-compliance-approve-uploaded-records"} component={ComplianceQaPage}/>
            <Route exact path={"/invoicemanagement-reports-dashboard"} component={InvoiceDashboardPage}/>
            <Route exact path={"/invoicemanagement-reports-tracker"} component={InvoiceReportTrackerPage}/>
            <Route exact path={"/invoicemanagement-generators-labour"} component={InvoiceDraftLabourPage}/>
            <Route exact path={"/invoicemanagement-generators-service"} component={InvoiceDraftServicePage}/>
            <Route exact path={"/invoicemanagement-configure-createdebtors"} component={InvoiceSetupDebtorPage}/>
            <Route exact path={"/invoicemanagement-configure-createprojects"} component={InvoiceSetupDebtorPage}/>
            <Route exact path={"/invoicemanagement-configure-setup-payment-terms"} component={InvoiceSetupTermsPage}/>
            <Route exact path={"/invoicemanagement-configure-load-purchase-orders"}
                   component={ManagePurchaseOrderPage}/>
            <Route exact path={"/invoicemanagement-configure-set-invoice-sequence"}
                   component={ManageInvoiceSequencePage}/>
            <Route exact path={"/invoicemanagement-setup-service-define-item"} component={InvoiceSetupItemPage}/>
            <Route exact path={"/invoicemanagement-setup-service-define-periods"} component={InvoiceSetupPeriodPage}/>
            <Route exact path={"/invoicemanagement-setup-service-define-sites"} component={InvoiceSetupSitePage}/>
            <Route exact path={"/invoicemanagement-setup-service-define-banking"} component={InvoiceSetupBankingPage}/>
            <Route exact path={"/invoicemanagement-setup-service-define-header"} component={InvoiceSetupHeaderPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-item"}
                   component={SetupLabourInvoiceItemDescription}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-periods"}
                   component={SetupLabourInvoiceItemPeriodPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-sites"}
                   component={SetupLabourInvoiceSiteListingPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-banking"}
                   component={SetupLabourInvoiceItemBankingDetailPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-header"}
                   component={SetupLabourInvoiceItemHeaderPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-workings-days"}
                   component={SetupLabourInvoiceItemSiteWorkingDayPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-designations"}
                   component={SetupLabourInvoiceDesignationsPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-site-employees"}
                   component={SetupLabourInvoiceSiteDesignationsPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-key-personnel"}
                   component={SetupLabourInvoiceItemKeyPersonnelPage}/>
            <Route exact path={"/invoicemanagement-setup-labour-define-vaccination-items"}
                   component={SetupLabourInvoiceItemVaccinationPage}/>
            <Route exact path={"/invoicemanagement-reports-account-statement"}
                   component={InvoiceAccountStatementPage}/>
            <Route exact path={"/invoicemanagement-extra-config-debtor-account-statement"}
                   component={InvoiceExtraSettingAccountStatementPage}/>


            <Route exact path={"/settings-onboard-company-name"} component={SuperCompanyOnboardPage}/>
            {/*<Route exact path={"/settings-onboard-company-financial-year"} component={} />*/}
            <Route exact path={"/settings-new-project-naming"} component={DatabaseSettingEntityPage}/>
            <Route exact path={"/settings-new-project-database-field"} component={DatabaseSettingAttributePage}/>
            <Route exact path={"/settings-new-project-employee-code-prefix"}
                   component={DatabaseSettingEmployeeCodeRulesPage}/>
            <Route exact path={"/settings-new-project-edit-database-field-structure"}
                   component={DatabaseSettingAttributePage2}/>
            <Route exact path={"/settings-new-project-contract-detail"} component={DatabaseSettingContractPage}/>
            <Route exact path={"/settings-uploading-employee-records"} component={DatabaseImportEmployeeCsvPage}/>
            <Route exact path={"/settings-uploading-employee-clocks"} component={ReportPayrollUploadBulkCsv}/>
            <Route exact path={"/settings-billing-active-accounts"} component={SuperCompanyActivePage}/>
            <Route exact path={"/settings-billing-activate-accounts"} component={SuperCompanyActivePage}/>
            <Route exact path={"/settings-manage-access-define-user-role"} component={SuperUserRolePage}/>
            <Route exact path={"/settings-manage-access-setup-access-Rules"} component={SuperAccessLevelPage}/>
            <Route exact path={"/settings-manage-access-define-credential-attributes"}
                   component={MobileLoginSetupPage}/>
            <Route exact path={"/settings-manage-access-permission-user-role-access"}
                   component={SettingUsersManagePage}/>
            <Route exact path={"/settings-utilities-set-public-holidays"} component={SuperPublicHolidayPage}/>
            <Route exact path="/settings-manage-access-setup-pmis2-mobile-bullk-user"
                   component={MobileBulkClockUserPage}/>
            <Route exact path="/invoicemanagement-configure-set-virtual-company"
                   component={ManageInvoiceVirtualCompanyPage}/>
            <Route exact path="/invoicemanagement-configure-set-virtual-company-debtor"
                   component={ManageInvoiceVirtualCompanyDebtorPage}/>
            <Route exact path="/wagepayroll-dashboard-contract" component={DashboardWagePayrollPage}/>
            <Route exact path="/integration-pastel-payment-project" component={IntegrationPastelProjectPage}/>
            <Route exact path="/integration-pastel-payment-field-attribute"
                   component={IntegrationPastelFieldAttributePage}/>
            <Route exact path="/integration-pastel-payment-period" component={IntegrationPastelPayPeriodPage}/>
            <Route exact path="/wagepayroll-configure-area" component={SettingExtraConfigureAreaPage}/>
            <Route exact path="/invoicemanagement-configure-set-take-onboard-balance"
                   component={ManageInvoiceTakeOnboardBalancePage}/>
            <Route exact path="/invoicemanagement-configure-load-transaction-adhoc"
                   component={ManageInvoiceTransactionAdhocPage}/>
            <Route exact path="/wagepayroll-configure-area-site"
                   component={SettingExtraConfigureAreaSitePage}/>
            <Route exact path="/wagepayroll-configure-bank-csv-fnb"
                   component={SettingExtraConfigureBankCsvFnbPage}/>
            <Route exact path="/payslip-demo"
                   component={PayslipDemo}/>
            <Route exact path="/invoicemanagement-configure-set-labour-cover"
                   component={ManageInvoiceLabourReportSettingPage}/>
            <Route exact path="/invoicemanagement-extra-config-debtor-inv-signature"
                   component={InvoiceExtraSettingSignaturePage}/>


            {/*****THIS IS THE END OF THE NEW MENUS************/}

            <Route exact path="/database-setting-entity" component={DatabaseSettingEntityPage}/>
            <Route exact path="/database-setting-attribute" component={DatabaseSettingAttributePage}/>
            <Route exact path={"/database-setting-attribute2"} component={DatabaseSettingAttributePage2}/>
            <Route exact path="/database-setting-record" component={DatabaseSettingRecordPage}/>
            <Route exact path="/database-setting-contract" component={DatabaseSettingContractPage}/>
            <Route exact path="/database-setting-record-csv" component={DatabaseImportEmployeeCsvPage}/>
            <Route exact path="/database-setting-record-onboard-rules"
                   component={DatabaseSettingEmployeeCodeRulesPage}/>
            <Route exact path="/settings-user-company" component={SettingUsersManagePage}/>
            <Route exact path="/settings-user-bulk" component={MobileBulkClockUserPage}/>
            <Route exact path="/super-setting-company-new" component={SuperCompanyOnboardPage}/>
            <Route exact path="/super-setting-company-active" component={SuperCompanyActivePage}/>
            <Route exact path="/reporting-payroll-company-day" component={ReportPaymentCyclePage}/>
            <Route exact path="/reporting-payroll-company-hours" component={ReportPaymentCycleHoursPage}/>
            <Route exact path="/workboard-Payroll-clocking" component={ClockingBulkPage}/>
            <Route exact path="/workboard-Payroll-qa" component={QaClockingPage}/>
            <Route exact path="/settings-user-employee" component={MobileLoginSetupPage}/>
            <Route exact path="/workboard-database-qa-employee" component={DatabaseQaOnboardingPage}/>
            <Route exact path="/deduction-employee-logging" component={DeductionEmployeeRegister}/>
            <Route exact path="/reporting-payroll-upload-bulk-csv" component={ReportPayrollUploadBulkCsv}/>
            <Route exact path="/compliance-setup-required" component={ComplianceSetupRequiredPage}/>
            <Route exact path="/compliance-setup-ratio" component={ComplianceRatioPage}/>
            <Route exact path="/compliance-setup-qa" component={ComplianceQaPage}/>
            <Route exact path="/settings-util-phh" component={SuperPublicHolidayPage}/>
            <Route exact path="/settings-wage-overtime" component={SettingWageOvertimePage}/>
            <Route exact path="/settings-wage-pph" component={SettingWagePublicHolidayPage}/>
            <Route exact path="/settings-wage-attribute-mapping" component={SettingWageAttributeMappingPage}/>
            <Route exact path="/reporting-payroll-clocking-summary" component={ReportPayrollClockingSummaryPage}/>
            <Route exact path="/invoice-draft-labour" component={InvoiceLabourAllPage}/>
            <Route exact path="/settings-invoice-purchase-order" component={SettingInvoiceLabourPurchaseNumber}/>
            <Route exact path="/settings-invoice-labour-heading" component={SettingInvoiceLabourHeadingPage}/>
            <Route exact path="/settings-invoice-vaccination" component={SettingInvoiceLabourVaccinationPage}/>
            <Route exact path="/invoice-setup-service-item" component={InvoiceSetupItemPage}/>
            <Route exact path="/invoice-setup-service-site" component={InvoiceSetupSitePage}/>
            <Route exact path="/invoice-setup-service-period" component={InvoiceSetupPeriodPage}/>
            <Route exact path="/invoice-setup-service-banking" component={InvoiceSetupBankingPage}/>
            <Route exact path="/invoice-setup-service-heading" component={InvoiceSetupHeaderPage}/>
            <Route exact path="/invoice-setup-service-debtors" component={InvoiceSetupDebtorPage}/>
            <Route exact path="/invoice-manage-purchase-order" component={ManagePurchaseOrderPage}/>
            <Route exact path="/invoice-manage-sequence-start" component={ManageInvoiceSequencePage}/>
            <Route exact path="/invoice-draft-services" component={InvoiceDraftServicePage}/>
            <Route exact path="/invoice-setup-service-terms" component={InvoiceSetupTermsPage}/>
            <Route exact path="/invoice-report-tracker" component={InvoiceReportTrackerPage}/>
            <Route exact path="/invoice-report-account-receivable" component={InvoiceReportAccountReceivablePage}/>
            <Route exact path="/invoice-draft-labour2" component={InvoiceDraftLabourPage}/>
            <Route exact path="/invoice-setup-service-project" component={ManageInvoiceProjectPage}/>
            <Route exact path="/invoice-setup-labour-project" component={ManageInvoiceProjectPage}/>

            <Route exact path="/settings-invoice-labour-item" component={SetupLabourInvoiceItemDescription}/>
            <Route exact path="/settings-invoice-labour-period" component={SetupLabourInvoiceItemPeriodPage}/>
            <Route exact path="/settings-invoice-labour-site-listing" component={SetupLabourInvoiceSiteListingPage}/>
            <Route exact path="/settings-invoice-labour-site-working-day"
                   component={SetupLabourInvoiceItemSiteWorkingDayPage}/>
            <Route exact path="/settings-invoice-labour-header" component={SetupLabourInvoiceItemHeaderPage}/>
            <Route exact path="/settings-invoice-labour-banking-detail"
                   component={SetupLabourInvoiceItemBankingDetailPage}/>
            <Route exact path="/settings-invoice-key-personnel" component={SetupLabourInvoiceItemKeyPersonnelPage}/>
            <Route exact path="/settings-invoice-labour-vaccination" component={SetupLabourInvoiceItemVaccinationPage}/>
            <Route exact path="/settings-invoice-labour-designations" component={SetupLabourInvoiceDesignationsPage}/>
            <Route exact path="/settings-invoice-labour-site-designation"
                   component={SetupLabourInvoiceSiteDesignationsPage}/>

            <Route exact path="/reporting-dashboard-invoicing" component={InvoiceDashboardPage}/>
            <Route exact path="/settings-access-company-role-access" component={SuperAccessLevelPage}/>
            <Route exact path={"/settings-user-role"} component={SuperUserRolePage}/>
            <Route exact path="/access-authorize-denied" component={denied}/>

            <Route exact path={"/settings-manage-access-setup-executive-mobile-user"}
                   component={MobileProRegisterUserPage}/>
            <Route exact path={"/privacy-policy"} component={PrivacyPolicy}/>

            <Route exact path={"/wagepayroll-rate-setup-define-rate"} component={PayrollWageDefineRatePage}/>
            <Route exact path={"/wagepayroll-rate-setup-define-overtime"} component={PayrollWageDefineOvertimePage}/>
            <Route exact path={"/wagepayroll-rate-setup-define-statutory"} component={PayrollWageDefineStatutoryPage}/>
            <Route exact path={"/wagepayroll-rate-setup-define-working-days"}
                   component={PayrollWageDefineWorkingDaysPage}/>

            <Route exact path={"/wagepayroll-labour-setup-period"}
                   component={LabourPeriodPage}/>
            <Route exact path={"/wagepayroll-labour-generate-report"}
                   component={LabourGenerateReportPage}/>

            <Route exact path={"/services-delivery-daily-logger"}
                   component={ServicesDeliveryDailyLoggerPage}/>
            <Route exact path={"/services-logs-vehicle"}
                   component={ServiceLogsVehiclePage}/>
            <Route exact path={"/services-logs-driver"}
                   component={ServiceLogsDriverPage}/>
            <Route exact path={"/services-logger-financial-tracker"}
                   component={ServiceLogsFinancialTrackerPage}/>
            <Route exact path={"/labour-logging-daily-logs"}
                   component={LabourLoggingDailyLogsPage}/>

            <Route exact path={"/labour-logging-import-csv"}
                   component={LabourLoggingImportCsvPage}/>

            <Route exact path={"/labour-logging-import-clocks"}
                   component={LabourLoggingSyncPage}/>

            <Route exact path={"/labour-logging-export-csv"}
                   component={LabourLoggingExportPage}/>
            <Route exact path={"/labour-logging-export-timesheet-csv"}
                   component={LabourLoggingExportTimesheetCsvPage}/>

            <Route exact path={"/services-logger-entry-form"}
                   component={ServiceLogsFormPage}/>
            <Route exact path={"/services-logs-contractor"}
                   component={ServiceContractorPage}/>
            <Route exact path={"/services-logs-contractor-vehicle"}
                   component={ServiceContractorVehiclePage}/>


            <Route exact path={"/services-logger-csv-import"}
                   component={ServiceLogsImportCsvPage}/>


            <Route exact path={"/services-logger-coct-summary"}
                   component={ServiceLogsCoCtSummaryPage}/>

            <Route exact path={"/labour-logging-coct-monthly-report"}
                   component={LabourLoggingMonthlyCoCTPage}/>

            <Route exact path={"/invoicemanagement-generators-keypersonnel"}
                   component={InvoiceDraftKeyPersonnelPage}/>

            <Route exact path={"/invoicemanagement-generators-vaccination"}
                   component={InvoiceDraftVaccinationPage}/>

            <Route exact path={"/labour-setting-site-listing"}
                   component={LabourSettingSiteListingPage}/>

                <Route exact path={"/labour-setting-invoice-template"}
                       component={LabourSettingInvoiceTemplatePage}/>

                <Route exact path={"/quotation-setting-labour-site-listing"}
                       component={QuoteLabourPlannedSitePage}/>

                <Route exact path={"/quotation-setting-labour-designation-rate"}
                       component={QuoteLabourPlannedRatePage}/>
                <Route exact path={"/quotation-setting-service-site-listing"}
                       component={QuoteServicePlannedSitePage}/>

                <Route exact path={"/quotation-setting-company-inv-prefix"}
                       component={QuoteCompanyInvPrefixPage}/>

                <Route exact path={"/quotation-setting-company-header-labour"}
                       component={QuoteCompanyInvHeaderPage}/>
                <Route exact path={"/quotation-setting-company-signature"}
                       component={QuoteCompanyInvSignaturePage}/>
                <Route exact path={"/quotation-setting-vaccination-tools"}
                       component={QuoteVaccinationToolsPage}/>
                <Route exact path={"/quotation-setting-labour-keypersonnel"}
                       component={QuoteKeyPersonalPage}/>

            <Route exact path={"/quotation-setting-water-tanker"}
                   component={QuoteWaterTankerPage}/>

                <Route exact path={"/quotation-generate-coct-labour"}
                       component={QuoteGenerateLabourPlannedPage}/>

            <Route exact path={"/quotation-report-tracker"}
                   component={QuoteReportTrackerPage}/>

            <Route exact path={"/quotation-setting-labour-workinghours"}
                   component={QuoteLabourWorkingHoursPage}/>

            <Route exact path={"/quotation-generate-coct-service"}
                   component={QuoteGenerateServicePlannedPage}/>

            <Route exact path={"/quotation-setting-company-header-service"}
                   component={QuoteCompanyInvHeaderServicePage}/>

                <Route exact path={"/quotation-generate-coct-water-tanker"}
                       component={QuoteGenerateWaterTankerPage}/>

            <Route exact path={"/quotation-generate-coct-key-personnel"}
                   component={QuoteGenerateKeyPersonnelPage}/>

            <Route exact path={"/quotation-generate-coct-vaccination"}
                   component={QuoteGenerateVaccinationPage}/>

            <Route exact path={"/invoicemanagement-generators-sanitech"}
                   component={InvoiceDraftSanitechPage}/>

            <Route exact path={"/invoicemanagement-generators-moreki"}
                   component={InvoiceDraftMorekiPage}/>

                <Route exact path={"/invoicemanagement-generators-bidvest"}
                       component={InvoiceDradtBidvestPage}/>

                <Route exact path="*" component={NotFoundPage}/>
        </Switch>
    )
}

function App() {
    LoadConfigInfo().then(r => {

    })
    return (
        <Provider store={store}>
            <Router history={history}>
                {!isMobile ? webRouter() : webRouter()}
            </Router>

        </Provider>
    );
}

function App2() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

export default App;
