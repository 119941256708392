import React from "react";
import Configures from "../configures/index";
import {GetLoginToken} from "./token";
import {GetConfigures} from "../configures/serve";

export const PostToPbx=async  (hub,endpoint,callback)=>{
    const server =GetConfigures();
    let url = server + "" + endpoint;
    const token = GetLoginToken();

    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((response) => {
            //todo let check if the response code is 401 Unauthorized
            if(typeof response.error !=="undefined"){
                if( response.error ==="Unauthorised access to this resource"){
                    //alert("Unauthorised access to this resource, Please login!")
                    window.location.href = "#/login"
                }
            }
            callback(response, null);
        }).catch((e) => {
        callback(null, e, null);
    });

}

export  const SubmitPost2 = async (hub, endpoint, successMsg,_this,reloadFunction,callback) => {

    await PostToPbx(hub, endpoint, async (data) => {

        if(successMsg!=="") {
            _this.setState({
                isLoading: false,
                component: "list"
            })
        }
        if (data === null) {
            //alert("Server connection Error! try again later");
            return
        }
        if(data.STATUS==="fail"){
            alert("Fail, could not insert your extension,try again")
        }

        if(successMsg!==""){
            alert(successMsg);
            _this.setState({
                isLoading: false,
                component: "list"
            });
            if(typeof reloadFunction!=="undefined"){
                reloadFunction()
            }
            if(typeof callback!=="undefined"){
                callback("done")
            }
        }



    });
}


