import React from "react";
import {fetchDataEntity} from "../../../../api/entity-data";
import {
    BuilderOptionComboBox,
    CheckConditions,
    FormatMoneySouthAfrica,
    LoadDataCommon,
    SortData
} from "../../../../api/fn";
import moment from "moment";
import Title from "../../../../components/Common/Title";
import {Button, Col, Divider, Row, Table} from "antd";
import Page from "../../../../layout/web/Page";
import Connector from "../../../../redux/connector";
import {PostToPbx} from "../../../../api/http-post";
import LoadingProcessing from "../../../../components/Common/page/Common/LoadingProcessing";
import LabourInvoiceAdhoc from "../../../../components/Invoicings/LabourInvoice/LabourInvoiceAdhoc";


class InvoiceDraftLabourPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Companies: [],
            Clients: [],
            Projects:[],
            Items: [],
            Data: [],
            Sequences: [],
            Periods: [],
            SiteDesignations:[],
            Designations:[],
            Sites:[],
            InvoiceTerms:[],
            PurchaseOrders:[],
            isLoading: false,
            newEntry: {
                Year: moment().year()
            },
            selectedCompany: "",
            selectedClient: "",
            selectedProject:"",
            selectedItem: "",
            selectedYear: "",
            selectedMonth: "",
            searchKey: "",

            selectedPeriodYear: "",
            selectedPeriodMonth: "",
            selectedPeriodNumber: "",
            selectedPeriodStart: "",
            selectedPeriodEnd: "",

            selectedPurchaseOrder:null,

            showForm: false,
            showDrawerAdhoc:false,

            Result:null,

        }
    }

    componentDidMount = async () => {
        await this.loadInitialData();
        this.getInvoiceDueDate();
    }
    loadInitialData = async () => {
        await fetchDataEntity("Companies", "client/entity", "company", this)
        await LoadDataCommon("modules", "Modules", this);
        await LoadDataCommon("SettingInvoiceServiceDebtors", "Clients", this);
        await LoadDataCommon("SettingInvoiceAddonProject", "Projects", this);

        await LoadDataCommon("SettingInvoiceAddonLabourItems", "Items", this);
        await LoadDataCommon("PurchaseOrder", "PurchaseOrders", this);
        await LoadDataCommon("InvoiceNumberInfo", "Sequences", this);
        await LoadDataCommon("SettingInvoiceAddonLabourItemPeriod", "Periods", this);
        await LoadDataCommon("SettingInvoiceAddonLabourSiteDesignation", "SiteDesignations", this);
        await LoadDataCommon("SettingInvoiceAddonLabourDesignations", "Designations", this);
        await LoadDataCommon("SettingInvoiceAddonLabourSites", "Sites", this);
        await LoadDataCommon("InvoiceTracker", "InvoiceTrackers", this);
        await LoadDataCommon("SettingInvoiceServiceAddonTerms", "InvoiceTerms", this);



    }
    onCloseDrawerAdhoc=()=>{
        this.setState({showDrawerAdhoc: false})
    }
    closeDrawer = () => {
        this.setState({showForm: false})
    }
    handleGeneralEntryInput = (val, key) => {
        let tmp = this.state;
        tmp.newEntry[key] = val;
        this.setState(tmp)
    }
    handleInput = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp)
    }
    handleInputPeriod = (val, key) => {
        let tmp = this.state;
        tmp[key] = val;
        let record = JSON.parse(val);
        tmp.selectedPeriodYear = record.year;
        tmp.selectedPeriodMonth = record.month;
        tmp.selectedPeriodNumber = record.periodnumber;
        tmp.selectedPeriodStart = record.startdate;
        tmp.selectedPeriodEnd = record.enddate;
        this.setState(tmp)
    }
    findPurchaseOrder=()=>{
        let pod = null
        for (let i in this.state.PurchaseOrders) {
            let row = this.state.PurchaseOrders[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.year !== this.state.selectedPeriodYear) {
                continue
            }
            if (row.month !== this.state.selectedPeriodMonth) {
                continue
            }
            if(row.client!==this.state.selectedClient){
                continue
            }
            if (row.item !== this.state.selectedItem) {
                continue
            }

            pod = row
        }

        if(pod!==null)
            console.log("findPurchaseOrder > ",pod," > ",pod.refnumber||"none")
        return pod;
    }
    getNextSequenceNumber = () => {
        let ls=[];
        for (let i in this.state.InvoiceTrackers) {
            let row = this.state.InvoiceTrackers[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            let current =0;
            try {
                current =parseInt( row.invoicenumber);
                if(!isNaN(current)){
                    ls.push(current);
                }
            }catch (e) {

            }

        }
        ls.sort(function(a, b) {
            return a - b;
        });
        console.log(ls);
        let index = ls.length;
        let nextValue = ls[index-1];

        if(typeof nextValue ==="undefined"){
            nextValue = 0
        }
        return nextValue + 1;
    }
    getPeriodData = () => {

        let ls = [];
        let conditions = [
            {key: "org", val: this.state.selectedCompany},
            /*{key: "project", val: this.state.selectedProject},*/
            {key: "client", val: this.state.selectedClient},
            {key: "item", val: this.state.selectedItem},
        ]
        let data = SortData("periodnumber",this.state.Periods)
        for (let i in data) {
            let row = data[i];
            if (!CheckConditions(row, conditions)) {
                continue
            }
            let from = moment(row.startdate, "YYYY-MM-DD").format("DD-MMM-YYYY");
            let to = moment(row.enddate, "YYYY-MM-DD").format("DD-MMM-YYYY");
            let record = {
                Ref: `${JSON.stringify(row)}`,
                Description: `Number: ${row.periodnumber} | Y: ${row.year} | M: ${row.month} | From: ${from} To: ${to}`
            }
            ls.push(record)
        }
        return ls
    }
    findTemplate=(org,client,project,item)=>{
        let template = "";
        for(let i in this.state.Items){
            let row = this.state.Items[i];
            if(row.org!==org){continue}

            if(row.client!==client){continue}
            console.log(`project ::) ${row.project} == ${project}`)
            if(row.project!==project){continue}
            if(row.name!==item){continue}
            template = row.template
        }
        return template
    }
    getProjectNameFromCode=(code)=>{
        for(let i in this.state.Projects){
            let row =this.state.Projects[i];
            if(row.code===code){
                return row.name
            }
        }
        return code
    }
    submitAdhocInvoice=async (/*{
                            Designations,
                            Sites,
                            SiteDesignations
                        }*/)=>{
        let _this =this;
        let state = this.state;
        let pod = this.findPurchaseOrder();

        let post={
            /*Mode:"ad hoc",
            AdhocDesignations:Designations,
            AdhocSites:Sites,
            AdhocSiteDesignations:SiteDesignations,*/

            Org           :state.selectedCompany,
            Project       :this.getProjectNameFromCode(state.selectedProject),
            ProjectPayroll:this.getProjectNameFromCode(state.selectedProject),
            Client    :state.selectedClient,
            PeriodYear    :state.selectedPeriodYear,
            PeriodMonth   :state.selectedPeriodMonth,
            Template      :this.findTemplate(state.selectedCompany,state.selectedClient,state.selectedProject,state.selectedItem),
            InvoiceName   :state.selectedItem,
            InvoiceNumber : `${this.getNextSequenceNumber()}`,
            Pod: pod,
        }

        let endpoint = "/invoices/labour/draft";
        this.setState({
            isLoading: true,
            selectedPurchaseOrder:pod,
            showDrawerAdhoc:false,
        });
        console.log("submitGenerate adhoc send > ",post);
        await PostToPbx(post, endpoint, async (data) => {
            console.log("submitGenerate response > ",data);
            _this.setState({isLoading: false,Result:data.RESULT.Result});
        });
    }

    onViewInvoice=(downloadLink)=>{
        document.title = "PMIS Service Invoice";
        window.open(downloadLink, '_blank');
    }
    onDownloadInvoice=(downloadLink)=>{
        let url = downloadLink;
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var a = document.createElement('a'); // create html element anchor
            a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
            a.download = `Service Invoice ${this.getNextSequenceNumber()}`; // Set the file name.
            a.style.display = 'none'; // set anchor as hidden
            document.body.appendChild(a);
            a.click();
            a.remove()
        };
        xhr.open('GET', url);
        xhr.send();
    }
    getInvoiceDate=()=>{
        let date =this.state.selectedPeriodEnd;
        if(date===""){
            date = moment().format("YYYY-MM-DD")
        }
        return date;
    }
    findInvoiceTerms=()=>{
        let num = 12;
        for (let i in this.state.InvoiceTerms) {
            let row = this.state.InvoiceTerms[i];
            if (row.org !== this.state.selectedCompany) {
                continue
            }
            if (row.org !== this.state.selectedClient) {
                continue
            }
            num = row.days
        }
        return num;
    }
    getInvoiceDueDate=()=>{
        let convertStringDateIntoTime=(inString)=>{
            let arr = inString.split("-");
            let year = parseInt(arr[0]);
            let month = parseInt(arr[1]);
            let day = parseInt(arr[2])
            return new Date(year, month, day)
        }
        let invDate = this.getInvoiceDate();
        let terms = this.findInvoiceTerms();
        let myDate = convertStringDateIntoTime(invDate);
        myDate.setDate(myDate.getDate() + terms);
        let strYear = myDate.getFullYear();
        let strMonth = myDate.getMonth();
        if(strMonth<10){strMonth = "0"+strMonth}
        let strDay = myDate.getDate();
        return `${strYear}-${strMonth}-${strDay}`
    }
    saveResultToInvoiceTracker =async () => {
        let _this = this;
        let state  = this.state;
        let info = this.state.Result;
        let invoiceNumber = this.getNextSequenceNumber();
        let pod = this.findPurchaseOrder();
        let totalAmount = info.TotalAmount;
        let subAmount = info.Subtotal;
        let vatAmount = info.Vat;
        if(pod===null){
            pod={
                refnumber:"",
                deliverydate:"",
                orderquantity:0,
                downloadlink:"",
            }
        }

        let record = {
            AppName:"pmis",
            Org: state.selectedCompany,
            Client: state.selectedClient,
            Item: state.selectedItem,

            InvoiceNumber: `${invoiceNumber}`,
            PoNumber: this.state.Result.PurchaseOrder.RefNumber,
            InvoiceDate: this.getInvoiceDate(),
            InvoiceAmount: totalAmount,
            PoExVat: subAmount,
            PoVat: vatAmount,
            PoTotal: pod.orderquantity,
            PaidAmount: 0,
            Terms: this.findInvoiceTerms(),
            DueDate: pod.deliverydate!==""?pod.deliverydate:this.getInvoiceDueDate(),
            Category: "labour",
            LinkInvNumber: info.DownloadLink,
            LinkPoNumber: pod.downloadlink,

            EntryType: "",
        }

        console.log("on About to submit inv tracker: ",record);
        if(!window.confirm("Are you to submit this invoice tracker?")){
            return
        }

        let endpoint = "/invoices/tracker/insert";
        this.setState({
            isLoading: true,
        })
        await PostToPbx(record, endpoint, async (data) => {
            alert("thank you, tracker  submitted, please check our tracker record!");
            _this.setState({isLoading: false,Result:null});

            await _this.loadInitialData();

        });

        console.log("onSubmitRecord > ",record);

    }
    renderResult=()=>{
        const toFix2=(inValue)=>{
            let val = inValue.toFixed(2)
            return parseFloat(val)
        }
        const tableHeadJson = [
            {field:'Action',label:'#',align:"center"},
            {field:'Period',label:'Period',align:"left"},
            {field:'InvoiceNumber',label:'Invoice No.',align:"left"},
            {field:'PONumber',label:'PO Number',align:"left"},
            /*{field:'TotalUnit',label:'Units',align:"center"},
            {field:'TotalServices',label:'Services',align:"center"},
            {field:'TotalSite',label:'Sites',align:"center"},*/
            {field:'Subtotal',label:'Subtotal',align:"right"},
            {field:'Vat',label:'Vat',align:"right"},
            {field:'Total',label:'Total',align:"right"},
        ]
        let columns=[];
        for(let i  in tableHeadJson){
            let row = tableHeadJson[i];
            columns.push({
                title: row.label,
                dataIndex: row.field,
                align:row.align,
            });
        }

        let info = this.state.Result;
        let ls =[
            {
                Action:<>
                    <a onClick={()=>this.saveResultToInvoiceTracker()}>Save Tracker</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <a onClick={() => this.onViewInvoice(info.DownloadLink)}>Email To</a> &nbsp;&nbsp;|&nbsp;&nbsp;
                </>,
                Period:info.PeriodString,
                InvoiceNumber:info.InvNumber,
                PONumber:info.PurchaseOrder.RefNumber,
                Subtotal: "R "+FormatMoneySouthAfrica(toFix2(info.Subtotal)),
                Vat:"R "+FormatMoneySouthAfrica(toFix2(info.Vat)),
                Total:"R "+FormatMoneySouthAfrica(toFix2(info.TotalAmount)),
                TotalUnit:info.TotalQuantity,
                TotalSite:info.TotalSite,
                TotalServices:info.TotalNumberOfService,
            }
        ]

        return(
            <Table
                columns={columns}
                dataSource={ls}
                bordered
            />
        )

    }

    renderIFrameInvoice=()=>{
        let info = this.state.Result;
        var delayInMilliseconds = 500; // half a second

        /*setTimeout(function() {
            let iframe = document.getElementsByTagName('iframe')[0];
            iframe.style.background = 'white';
            iframe.contentWindow.document.body.style.backgroundColor = 'white';

        }, delayInMilliseconds);*/
        return (
            <iframe
                src={info.DownloadLink}
                title="Invoice"
                style={{minWidth:"100%",minHeight:1024,background: "#FFFFFF"}}
                frameborder="0"

            >
                Presss me: <a href={info.DownloadLink}>Download PDF</a>
            </iframe>
        )
    }



    RenderAdhoc=()=>{
        return null
        if(!this.state.showDrawerAdhoc){return null}
        return <LabourInvoiceAdhoc
            visible={this.state.showDrawerAdhoc}
            selectedClient={this.state.selectedClient}
            selectedCompany={this.state.selectedCompany}
            selectedItem={this.state.selectedItem}
            selectedProject={this.state.selectedProject}
            selectedPeriod={JSON.parse(this.state.selectedPeriod)}
            onClose={this.onCloseDrawerAdhoc}
            SitesInfo={this.state.Sites}
            SiteDesignationInfo={this.state.SiteDesignations}
            Designations={this.state.Designations}
            onSubmit={this.submitAdhocInvoice}
        />
    }

    render() {
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                }}>
                    <Title style={styles.title}>Generate Invoice Labour</Title>

                    <Divider style={{color: "red"}}>Filter</Divider>
                    <BuilderOptionComboBox
                        data={this.state.Companies}
                        keyVal={"CustomerNumber"}
                        keyLabel={"Name"}
                        label={"Select Organization"}
                        conditions={[]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedCompany"}
                        currentValue={this.state.selectedCompany}
                        comboId={"company"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Clients}
                        keyVal={"mame"}
                        keyLabel={"name"}
                        label={"Select Debtor"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany}
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedClient"}
                        currentValue={this.state.selectedClient}
                        comboId={"client"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Projects}
                        keyVal={"code"}
                        keyLabel={"name"}
                        label={"Select Project"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            {key: "client", val: this.state.selectedClient},
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedProject"}
                        currentValue={this.state.selectedItem}
                        comboId={"project"}
                    />
                    <BuilderOptionComboBox
                        data={this.state.Items}
                        keyVal={"name"}
                        keyLabel={"name"}
                        label={"Select Item | Job description"}
                        conditions={[
                            {key: "org", val: this.state.selectedCompany},
                            {key: "client", val: this.state.selectedClient},
                            {key: "project", val: this.state.selectedProject},
                        ]}
                        handleFunction={this.handleInput}
                        stateKey={"selectedItem"}
                        currentValue={this.state.selectedItem}
                        comboId={"item"}
                    />

                    <BuilderOptionComboBox
                        data={this.getPeriodData()}
                        keyVal={"Ref"}
                        keyLabel={"Description"}
                        label={"Select Period"}
                        conditions={[]}
                        handleFunction={this.handleInputPeriod}
                        stateKey={"selectedPeriod"}
                        currentValue={this.state.selectedPeriod}
                        comboId={"period"}
                    />


                    <Divider orientation="center" style={{color: "#3880ff"}}>
                        Next Sequence number: {this.getNextSequenceNumber()}
                        <span style={styles.podNumber}>
                            | PO number:
                            {this.findPurchaseOrder() !== null ? this.findPurchaseOrder().refnumber : "none"}
                        </span>
                    </Divider>

                    <Row>
                        <Col span={24}>
                            <Button onClick={()=>this.submitAdhocInvoice()} style={{minWidth:"100%",border:"1px solid red",borderRadius:5}} type={"warning"}>
                                Generate Invoice
                            </Button>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={24}>
                            <LoadingProcessing open={this.state.isLoading}/>
                        </Col>
                    </Row>

                    {this.state.Result!==null?
                        <Row>
                            <Col span={24}>
                                {this.renderResult()}
                            </Col>
                            <Col span={24}>
                                {this.renderIFrameInvoice()}
                            </Col>
                        </Row>:null}

                    {this.RenderAdhoc()}

                </div>
            </Page>

        )
    }

}


export default Connector(InvoiceDraftLabourPage);

const styles = {
    podNumber:{
      color:"orange",
        marginLeft:10
    },
    container: {},
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
