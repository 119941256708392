import Page from "../../../layout/web/Page";
import Title from "../../../components/Common/Title";
import React from "react";
import Connector from "../../../redux/connector";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Header from '../../../components/web/Home/Welcome/Header';
import MainFeaturedPost from '../../../components/web/Home/Welcome/MainFeaturedPost';
import FeaturedPost from '../../../components/web/Home/Welcome/FeaturedPost';
//import Main from '../../../components/web/Home/Welcome/Main';
//import Sidebar from '../../../components/web/Home/Welcome/Sidebar';
import Footer from '../../../components/web/Home/Welcome/Footer';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));

const sections = [
    /*{ title: 'Technology', url: '#' },
    { title: 'Design', url: '#' },
    { title: 'Culture', url: '#' },
    { title: 'Business', url: '#' },
    { title: 'Politics', url: '#' },
    { title: 'Opinion', url: '#' },
    { title: 'Science', url: '#' },
    { title: 'Health', url: '#' },
    { title: 'Style', url: '#' },
    { title: 'Travel', url: '#' },*/
];

const linkImage1="https://i0.wp.com/www.blueglobe27.com/wp-content/uploads/2019/03/PPA_HR_Payroll_Modules_Mar19Featured-Image.jpg?fit=1200%2C628";
const linkImage  ='https://slcontrols.com/wp-content/uploads/2019/02/What-is-ALCOA-and-Why-Is-It-Important-to-Validation-and-Data-Integrity.png';
const linkImage2 ="https://tse2.mm.bing.net/th?id=OIP.AXwGrxg_uyKLFJ3GMPo3SgHaHa&pid=Api&P=0&w=300&h=300";
const linkImage3="https://tse2.mm.bing.net/th?id=OIP.vsE8NONsIhqlQKlScxNEewHaHa&pid=Api&P=0&w=300&h=300";
const linkImage4="https://tse1.mm.bing.net/th?id=OIP._XCr34uptEgtCMXL5yUu7QAAAA&pid=Api&P=0&w=300&h=300";
//'https://source.unsplash.com/random',
const mainFeaturedPost = {
    title: 'Effortlessly Manage EPWP Payroll & Performance Reporting',
    description:
        "Stay on top of EPWP contracts with our integrated management system which uses custom-developed processes and information to productively and efficiently manage your programme payroll.",
    image: linkImage1,
    imgText: 'main image description',
    linkText: 'Continue reading…',
};

const featuredPosts = [
    {
        title: 'Database Management Integrity Validation',
        date: 'Database',
        description:
            'Customizable Company Set Up. Electronic take-on of employees. Employee records validated. Validation warnings. Employee database maintained per host company and linked to relative municipality and provincial or national government department ',
        image: linkImage,
        imageText: 'Image Text',
    },
    {
        title: 'Payroll and Time & Attendance Management ',
        date: 'Payroll',
        description:
            'Time & Attendance Management Customisable wage rates Payroll accuracy Edit and approve employee times View employee or global wage bill Manage multiple EPWP contracts Allows for weekly, fortnightly and monthly pay-runs ',
        image: linkImage2,
        imageText: 'Image Text',
    },
    {
        title: 'Eletronic Interfaces ',
        date: 'Eletronic',
        description:
            'Automated wage payment protocol to all banks Host company control of automated payment Secure 3rd party payments solution offered Significant bank transaction fee savings to service provider companies Seamless interface with Sage Pastel',
        image: linkImage3,
        imageText: 'Image Text',
    },
    {
        title: 'Intelligence Report Engien & Document',
        date: 'Intelligence',
        description:
            ' All employee records are digitised and electronic copies archived HR data analytics provides actionable payroll insights Auto generate U-19 reports Auto generate municipality labour reports Municipality invoice preparation and calculation Big data views',
        image: linkImage4,
        imageText: 'Image Text',
    },
];

const posts = [/*post1, post2, post3*/];

const sidebar = {
    title: 'About Us',
    description:
        'Where are trying to help many student who are interested to pass exams in DRD Congo. This tools will give yous everything you want to be the best.',
    archives: [
        { title: 'Jun 2019', url: '#' },
        { title: 'Jul 2018', url: '#' },
        { title: 'Jun 2017', url: '#' },
        { title: 'Jun 2016', url: '#' },
        { title: 'Jun 2015', url: '#' },
    ],
    social: [
        { name: 'GitHub', icon: GitHubIcon },
        { name: 'Twitter', icon: TwitterIcon },
        { name: 'Facebook', icon: FacebookIcon },
    ],
};


class HomeOverviewIndexPage extends React.Component{

    render() {
        let props = this.props;
        const { classes } = this.props;
        return (
            <Page {...this.props}>
                <div style={{
                    minHeight: "100%",
                    maxHeight: "100%",
                    maxWidth:"100%",
                    minWidth:"100%"
                }}>
                    {/*<Title style={styles.title}>Home</Title>*/}

                    <React.Fragment>
                        <CssBaseline />
                        <Container maxWidth="lg">
                            {/*<Header title="EasiPath Solutions" sections={sections} />*/}
                            <main>
                                <MainFeaturedPost post={mainFeaturedPost} />
                                <Grid container spacing={4}>
                                    {featuredPosts.map((post) => (
                                        <FeaturedPost key={post.title} post={post} />
                                    ))}
                                </Grid>
                                {/*<Grid container spacing={5} className={classes.mainGrid}>
                                    <Main title="Feedback from student" posts={posts} />
                                    <Sidebar
                                        title={sidebar.title}
                                        description={sidebar.description}
                                        archives={sidebar.archives}
                                        social={sidebar.social}
                                    />
                                </Grid>*/}
                            </main>
                        </Container>
                        <Footer title="PMIS" description="payroll management information system" />
                    </React.Fragment>

                </div>
            </Page>
        )
    }
}

HomeOverviewIndexPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = {
    container:{

    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}

export default withStyles(styles)(Connector(HomeOverviewIndexPage));



