import {Layout, Menu} from "antd";
import React from "react";
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import LogoImg from "../../assets/img/logo.png";
import AgentImg from "../../assets/img/background1.jpg";
import {GetLoginAccess, GetUserToken} from "../../api/token";
import {CheckDisplayMenu, GetMenuList, IsRoleIn, SetMenu} from "../../api/nav";
import {reducerLogin as login} from "../../redux/reducer";
import {GetUserNavigationMenus_} from "../../api/navigation-menus";
import connector from "../../redux/connector";
const {   Sider } = Layout;
const { SubMenu } = Menu;



class SideMenu extends React.Component{
    constructor(props) {
        super(props);
    }
    render() {
         const props = this.props
        const nav =props.nav;
        const login =props.login;
        const CurrentApp = nav.CurrentApp;
        const CurrentModule = nav.CurrentModule;
        const CurrentMenu = nav.CurrentMenu;
        const user = GetUserToken();
        const access = GetLoginAccess();
        let accessList=[];


        let navMenus={}
        let menuData=[];
        for(let i in access){
            let row = access[i];
            if(user.Role==="super"){
                menuData.push({
                    module:row.Module,
                    menu:row.Menu,
                    subMenu:row.Submenu,
                    link:row.Link,
                });
                continue
            }
            if(user.Org===row.Org && user.Role===row.Role){
                menuData.push({
                    module:row.Module,
                    menu:row.Menu,
                    subMenu:row.Submenu,
                    link:row.Link,
                });
            }
        }

        navMenus=GetUserNavigationMenus_(props,menuData)


        /*
        CurrentApp: localStorage.getItem("@pbx-app") || "pbx",
        CurrentModule: localStorage.getItem("@pbx-module") || "setting",
        CurrentMenu: localStorage.getItem("@pbx-menu") || "range",
         */
        let lsModules =[];
        for(let module in navMenus[CurrentApp]){
            lsModules.push(module);
        }

        const hasModuleHaveMenu=(targetMenu)=>{
            if(user.Role==="super"){
                return true
            }
            let boo=false;
            let info1Data = navMenus[CurrentApp];
            for(let i in info1Data){
                let d1 = info1Data[i];
                if(i!==targetMenu){continue}
                if (d1.length > 0) {
                    boo = true
                }
            }
            return boo
        }
        const hasModuleHaveMenuAndSubMenu=(targetMenu,targetSubmenu)=>{
            if(user.Role==="super"){
                return true
            }
            let boo=false;
            let info1Data = navMenus[CurrentApp];
            for(let i in info1Data){
                let d1 = info1Data[i];
                for(let a in d1){
                    let rowData = d1[a];
                    if(rowData.link===targetSubmenu){
                        boo=true
                    }
                }
            }
            return boo
        }

        return(
            <Sider width={275} className="site-layout-background" style={{minHeight:"100%"}}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={[CurrentModule]}
                    defaultOpenKeys={[CurrentMenu]}
                    style={{ height: '100%', minHeight: '100%',borderRight: 0 }}
                >
                    {lsModules.map((module)=>{
                        let lsMenus = GetMenuList(module,props);

                        if(!hasModuleHaveMenu(module)){return null}
                        /*if(!CheckDisplayMenu(CurrentApp,module,props)){
                            return null
                        }*/


                        return(
                            <SubMenu key={module} icon={<UserOutlined />} title={module}>
                                {lsMenus.map((menu,indexMenu)=>{

                                    /*if(!IsRoleIn(menu.roles)){
                                        return null
                                    }*/
                                    if(menu.link==="services-logger-financial-tracker"){
                                        console.log("((:::::::::----> ",menu)
                                    }
                                    if(!hasModuleHaveMenuAndSubMenu(module,menu.link)){
                                        return null
                                    }
                                    return(
                                        <Menu.Item
                                            key={menu.name}
                                            onClick={()=>SetMenu(module,menu,props)}
                                        >
                                            {menu.name}
                                        </Menu.Item>
                                    )
                                })}
                            </SubMenu>
                        )
                    })}
                    {/*<img src={LogoImg} style={{width:"100%"}}/>
                <img src={AgentImg} style={{width:"100%"}}/>*/}
                </Menu>
            </Sider>
        )
    }


}


export default connector(SideMenu);
