import {SET_APP_NAME, SET_APP_MODULE, SET_APP_MENU, SET_APP_CONTENT, SET_NAV_MENUS, SET_APP_LINK} from "../../constants"

const RoleAll = ["super", "admin", "client", "agent"];
const RoleSuper = ["super"];
const RoleAdmin = ["super", "admin"];
const RoleAgent = ["super", "admin", "agent"];

const getStorage = (key) => {
    let val = localStorage.getItem(key);
    if (typeof val === "undefined") {
        return "";
    }
    if (val === null) {
        return ""
    }
    return val;
}
const setStorage = (key, val) => {
    localStorage.setItem(key, val);
    return val;
}
const initial = {
    CurrentApp: localStorage.getItem("@pmis-app") || setStorage("@pmis-app", "pmis"),
    CurrentModule: getStorage("@pmis-module") || setStorage("@pmis-module", "home"),
    CurrentMenu: getStorage("@pmis-menu") || setStorage("@pmis-menu", "Overview"),
    CurrentLink: getStorage("@pmis-link") || setStorage("@pmis-link", "home-overview-index"),
    CurrentSubmenu: getStorage("@pmis-submenu") || setStorage("@pmis-submenu", ""),
    AllRole: RoleAll,
    menus: {
        'Home': {
            Home: [
                {
                    app: "pmis",
                    name: "Global Dashboard",
                    link: "home-home-global-dashboard",
                    roles: RoleAgent,
                    isDefault: true
                },
                {
                    app: "pmis",
                    name: "Overview",
                    link: "home-overview-index",
                    roles: RoleAgent,
                    isDefault: false
                },
            ]
        },
        'PAYROLL MINDER': {
            'Dashboards': [
                {
                    app: "pmis",
                    name: "Contract Dashboard",
                    link: "wagepayroll-dashboard-contract",
                    roles: RoleAgent,
                    isDefault: true
                },
               /* {
                    app: "pmis",
                    name: "Payroll Dashboard",
                    link: "wagepayroll-dashboard-payroll",
                    roles: RoleAgent,
                    isDefault: true
                },*/
                {
                    app: "pmis",
                    name: "Profitability Dashboard",
                    link: "wagepayroll-dashboard-payroll-Profitability",
                    roles: RoleAgent,
                    isDefault: true
                },
            ],
            /*'Employee Database': [
                {
                    app: "pmis",
                    name: "Employee Records",
                    link: "wagepayroll-employee-record",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pmis",
                    name: "Approve Employee OnBoarding",
                    link: "wagepayroll-employee-onboarding",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Time Tracking': [
                {
                    app: "pmis",
                    name: "Hourly Punching",
                    link: "wagepayroll-timetracking-hourlypunching",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pmis",
                    name: "Day Punching",
                    link: "wagepayroll-timetracking-daypunching",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pmis",
                    name: "Deductions",
                    link: "wagepayroll-timetracking-deductions",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pmis",
                    name: "Pension Provident Fund",
                    link: "wagepayroll-timetracking-pension-provident-fund",
                    roles: RoleAdmin,
                    isDefault: true
                },
                {
                    app: "pmis",
                    name: "Approve Employee Clocking",
                    link: "wagepayroll-timetracking-approve-employee-clocking",
                    roles: RoleAdmin,
                    isDefault: true
                },
            ],
            'Payroll': [
                {
                    app: "pmis",
                    name: "Payroll Report",
                    link: "wagepayroll-payroll-report",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Clock Summary",
                    link: "wagepayroll-payroll-clocksummary",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Payroll Preview",
                    link: "wagepayroll-payroll-payrollpreview",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Saved Payroll",
                    link: "wagepayroll-payroll-savedpayroll",
                    roles: RoleAdmin,
                    isDefault: false
                },

            ],
            'Payroll Actions': [
                {
                    app: "pmis",
                    name: "Banking Integration",
                    link: "wagepayroll-payroll-banking-integration",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Payment Gateway Integration",
                    link: "wagepayroll-payroll-payrollactions-gateway-ntegration",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Payment Pastel Integration",
                    link: "wagepayroll-payroll-payrollactions-pastel-ntegration",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Banking Payment Report",
                    link: "wagepayroll-payroll-payrollactions-banking-payment-report",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pmis",
                    name: "CoCT Payment Report",
                    link: "wagepayroll-payroll-payrollactions-coct-payment-report",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Payroll Payslips",
                    link: "wagepayroll-payroll-payrollactions-payroll-payslip",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Labour Report",
                    link: "wagepayroll-payroll-payrollactions-labour-report",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Timesheet Report",
                    link: "wagepayroll-payroll-payrollactions-timesheet-report",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "U19 Form",
                    link: "wagepayroll-support-document-u19",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Ufilling Report",
                    link: "wagepayroll-support-document-ufilling",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "EMP201 Report",
                    link: "wagepayroll-support-document-u201",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Wage Calculation Rules': [
                {
                    app: "pmis",
                    name: "Attribute Mapping",
                    link: "wagepayroll-calculationrules-attribute-mapping",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Overtime Rules",
                    link: "wagepayroll-calculationrules-overtime-rules",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Public Holidays Rules",
                    link: "wagepayroll-calculationrules-public-holiday-rules",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Compliance': [
                {
                    app: "pmis",
                    name: "Record Management Set Up",
                    link: "wagepayroll-compliance-record-management",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Thresholds",
                    link: "wagepayroll-compliance-thresholds",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Approve Uploaded Records",
                    link: "wagepayroll-compliance-approve-uploaded-records",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Document Configuration': [
                {
                    app: "pmis",
                    name: "FNB CSV Setup",
                    link: "wagepayroll-configure-bank-csv-fnb",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "ABSA CSV Setup",
                    link: "wagepayroll-configure-bank-csv-absa",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Capitec CSV Setup",
                    link: "wagepayroll-configure-bank-csv-capitec",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Nedbank CSV Setup",
                    link: "wagepayroll-configure-bank-csv-nedbank",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Standard Bank CSV Setup",
                    link: "wagepayroll-configure-bank-csv-std",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pmis",
                    name: "U19 Form Setup",
                    link: "wagepayroll-configure-u19-setup-info",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "EMP201 Setup",
                    link: "wagepayroll-configure-u201-setup-info",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pmis",
                    name: "Labour Report Area",
                    link: "wagepayroll-configure-area",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Labour Report Sites",
                    link: "wagepayroll-configure-area-site",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Labour cover Heading",
                    link: "invoicemanagement-configure-set-labour-cover",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Payslip Heading Setup",
                    link: "wagepayroll-configure-payslip-company-info",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Rate Setup': [
                {
                    app: "pmis",
                    name: "Define Rate",
                    link: "wagepayroll-rate-setup-define-rate",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Define Overtime Rule",
                    link: "wagepayroll-rate-setup-define-overtime",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Define Working normal days",
                    link: "wagepayroll-rate-setup-define-working-days",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Define Statutory",
                    link: "wagepayroll-rate-setup-define-statutory",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Labour (with period)': [
                {
                    app: "pmis",
                    name: "Setup labour period",
                    link: "wagepayroll-labour-setup-period",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Generate Labour",
                    link: "wagepayroll-labour-generate-report",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ]*/
        },
        'INVOICE MINDER': {
            'Reports': [
                {
                    app: "pmis",
                    name: "Dashboard",
                    link: "invoicemanagement-reports-dashboard",
                    roles: RoleAdmin,
                    isDefault: false
                },
                /*{
                    app: "pmis",
                    name: "Account Receivable",
                    link: "invoicemanagement-reports-account-receivable",
                    roles: RoleAdmin,
                    isDefault: false
                },*/
                {
                    app: "pmis",
                    name: "Debtor Account Statements",
                    link: "invoicemanagement-reports-account-statement",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Invoice Tracker",
                    link: "invoicemanagement-reports-tracker",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            /*'Invoice Generators': [
                {
                    app: "pmis",
                    name: "Labour Invoice",
                    link: "invoicemanagement-generators-labour",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Service Invoice",
                    link: "invoicemanagement-generators-service",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Key Personnel Invoice",
                    link: "invoicemanagement-generators-keypersonnel",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Vaccination Invoice",
                    link: "invoicemanagement-generators-vaccination",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Sanitech Invoice",
                    link: "invoicemanagement-generators-sanitech",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Moreki Invoice",
                    link: "invoicemanagement-generators-moreki",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Bidvest Invoice",
                    link: "invoicemanagement-generators-bidvest",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Configure Invoice Controls': [
                {
                    app: "pmis",
                    name: "Create Debtors",
                    link: "invoicemanagement-configure-createdebtors",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Create Projects",
                    link: "invoicemanagement-configure-createprojects",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Payment Terms",
                    link: "invoicemanagement-configure-setup-payment-terms",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Load Purchase Orders",
                    link: "invoicemanagement-configure-load-purchase-orders",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pmis",
                    name: "Set Invoice Sequence",
                    link: "invoicemanagement-configure-set-invoice-sequence",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pmis",
                    name: "Setup Internal Company",
                    link: "invoicemanagement-configure-set-virtual-company",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup  Internal Company Debtor",
                    link: "invoicemanagement-configure-set-virtual-company-debtor",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Take-Onboard Balance",
                    link: "invoicemanagement-configure-set-take-onboard-balance",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Load Adhoc Transaction",
                    link: "invoicemanagement-configure-load-transaction-adhoc",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Debtor Account Statement",
                    link: "invoicemanagement-extra-config-debtor-account-statement",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Debtor Invoice signature",
                    link: "invoicemanagement-extra-config-debtor-inv-signature",
                    roles: RoleAdmin,
                    isDefault: false
                },

            ],
            'Setup Services Invoice': [
                {
                    app: "pmis",
                    name: "Define item Description",
                    link: "invoicemanagement-setup-service-define-item",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Define Invoice Periods",
                    link: "invoicemanagement-setup-service-define-periods",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Sites to be serviced",
                    link: "invoicemanagement-setup-service-define-sites",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Company Banking Detail",
                    link: "invoicemanagement-setup-service-define-banking",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Invoice Header",
                    link: "invoicemanagement-setup-service-define-header",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Setup Labour Invoice': [
                {
                    app: "pmis",
                    name: "Define Labour Project",
                    link: "invoice-setup-labour-project",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Define item Description",
                    link: "invoicemanagement-setup-labour-define-item",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Define Invoice Periods",
                    link: "invoicemanagement-setup-labour-define-periods",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Sites Listing",
                    link: "invoicemanagement-setup-labour-define-sites",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Company Banking Detail",
                    link: "invoicemanagement-setup-labour-define-banking",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Invoice Header",
                    link: "invoicemanagement-setup-labour-define-header",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Employee Working Day",
                    link: "invoicemanagement-setup-labour-define-workings-days",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Employee Designations",
                    link: "invoicemanagement-setup-labour-define-designations",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Site Employees",
                    link: "invoicemanagement-setup-labour-define-site-employees",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Key Personnel",
                    link: "invoicemanagement-setup-labour-define-key-personnel",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Vaccination Items",
                    link: "invoicemanagement-setup-labour-define-vaccination-items",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Site Listing",
                    link: "labour-setting-site-listing",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Invoice Template",
                    link: "labour-setting-invoice-template",
                    roles: RoleAdmin,
                    isDefault: false
                },

            ],
            'Service Management':[
                {
                    app: "pmis",
                    name: "Daily Service Logs",
                    link: "services-delivery-daily-logger",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Services Financial Index",
                    link: "services-logger-financial-tracker",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "CoCT Service Summary",
                    link: "services-logger-coct-summary",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Subcontractor Service Performance",
                    link: "services-logger-subcontractor-performance",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Delivery Note Logging",
                    link: "services-logger-entry-form",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Delivery Note CSV Import",
                    link: "services-logger-csv-import",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pmis",
                    name: "Setup Vehicle",
                    link: "services-logs-vehicle",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Driver",
                    link: "services-logs-driver",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Sub Contractor",
                    link: "services-logs-contractor",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Allocate Contractor Vehicles",
                    link: "services-logs-contractor-vehicle",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Labour Management':[
                {
                    app: "pmis",
                    name: "Daily logs",
                    link: "labour-logging-daily-logs",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Import From Csv File",
                    link: "labour-logging-import-csv",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Import From Clocks",
                    link: "labour-logging-import-clocks",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Export Labour Report Logs",
                    link: "labour-logging-export-csv",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Export Timesheet Logs",
                    link: "labour-logging-export-timesheet-csv",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "CoCT Monthly Logs",
                    link: "labour-logging-coct-monthly-report",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ]*/
        },
        /*'Quotation Minder': {
            'Settings': [
                {
                    app: "pmis",
                    name: "Setup Labour Site Listing",
                    link: "quotation-setting-labour-site-listing",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Labour Designation Rate ",
                    link: "quotation-setting-labour-designation-rate",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Service Site Listing",
                    link: "quotation-setting-service-site-listing",
                    roles: RoleAdmin,
                    isDefault: false
                },

                {
                    app: "pmis",
                    name: "Setup Company Header Labour",
                    link: "quotation-setting-company-header-labour",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Company Header Service",
                    link: "quotation-setting-company-header-service",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Company Quote Number Prefix",
                    link: "quotation-setting-company-inv-prefix",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Company  Signature",
                    link: "quotation-setting-company-signature",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Load Vaccinatin Tools ",
                    link: "quotation-setting-vaccination-tools",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Labour Key Personnel",
                    link: "quotation-setting-labour-keypersonnel",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Labour Working Hour",
                    link: "quotation-setting-labour-workinghours",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Load Water Tanker",
                    link: "quotation-setting-water-tanker",
                    roles: RoleAdmin,
                    isDefault: false
                },

            ],
            'Generate': [
                {
                    app: "pmis",
                    name: "CoCT Planned Labour",
                    link: "quotation-generate-coct-labour",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "CoCT Planned Service ",
                    link: "quotation-generate-coct-service",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "CoCT Water Tanker ",
                    link: "quotation-generate-coct-water-tanker",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "CoCT Key Personnel ",
                    link: "quotation-generate-coct-key-personnel",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "CoCT Vaccination ",
                    link: "quotation-generate-coct-vaccination",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Report': [
                {
                    app: "pmis",
                    name: "Quote Tracker",
                    link: "quotation-report-tracker",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
        },
        'Contract Minder': {
            'Pastel To Gateway Payment': [
                {
                    app: "pmis",
                    name: "Set Project",
                    link: "integration-pastel-payment-project",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Field Attribute",
                    link: "integration-pastel-payment-field-attribute",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Pay Period",
                    link: "integration-pastel-payment-period",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Employee Database",
                    link: "integration-pastel-payment-database",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Salary Rate",
                    link: "integration-pastel-payment-salary-rate",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Payments",
                    link: "integration-pastel-payment-monthly-payment",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ]
        },
        'SETTINGS': {
            'Onboard New Company': [
                {
                    app: "pmis",
                    name: "Company Name",
                    link: "settings-onboard-company-name",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Set Company Financial Year",
                    link: "settings-onboard-company-financial-year",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'New Project Setup': [
                {
                    app: "pmis",
                    name: "Project Naming",
                    link: "settings-new-project-naming",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Database Field Structure",
                    link: "settings-new-project-database-field",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setu Employee code prefix",
                    link: "settings-new-project-employee-code-prefix",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Edit Database Field Structure",
                    link: "settings-new-project-edit-database-field-structure",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Contract Detail Setup",
                    link: "settings-new-project-contract-detail",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Uploading': [
                {
                    app: "pmis",
                    name: "Import Employee Records",
                    link: "settings-uploading-employee-records",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Bulk Clock Employees",
                    link: "settings-uploading-employee-clocks",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Billing': [
                {
                    app: "pmis",
                    name: "Active Accounts",
                    link: "settings-billing-active-accounts",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Suspended Accounts",
                    link: "settings-billing-suspended-accounts",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Activate Accounts",
                    link: "settings-billing-activate-accounts",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Payment Alert",
                    link: "settings-billing-payment-alert",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Manage Roles & Access': [
                {
                    app: "pmis",
                    name: "Define User Role",
                    link: "settings-manage-access-define-user-role",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Access Rules",
                    link: "settings-manage-access-setup-access-Rules",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Login Credential Attributes",
                    link: "settings-manage-access-define-credential-attributes",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "User Role Access",
                    link: "settings-manage-access-permission-user-role-access",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Admin Mobile User",
                    link: "settings-manage-access-setup-admin-mobile-user",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Pmis2 Mobile Bulk User",
                    link: "settings-manage-access-setup-pmis2-mobile-bullk-user",
                    roles: RoleAdmin,
                    isDefault: false
                },
                {
                    app: "pmis",
                    name: "Setup Executive Mobile User",
                    link: "settings-manage-access-setup-executive-mobile-user",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ],
            'Utilities': [
                {
                    app: "pmis",
                    name: "Set Public Holidays",
                    link: "settings-utilities-set-public-holidays",
                    roles: RoleAdmin,
                    isDefault: false
                },
            ]
        },*/
    },

}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SET_APP_NAME:
            newState.CurrentApp = action.payload;
            localStorage.setItem("@pmis-app", action.payload);
            return Object.assign({}, newState);
        case SET_APP_MODULE:
            newState.CurrentModule = action.payload;
            localStorage.setItem("@pmis-module", action.payload);
            return Object.assign({}, newState);
        case SET_APP_MENU:
            newState.CurrentMenu = action.payload;
            localStorage.setItem("@pmis-menu", action.payload);
            return Object.assign({}, newState);
        case SET_APP_CONTENT:
            newState.CurrentMenu = action.payload;
            localStorage.setItem("@pmis-content", action.payload);
            return Object.assign({}, newState);
        case SET_APP_LINK:
            newState.CurrentLink = action.payload;
            localStorage.setItem("@pmis-link", action.payload);
            return Object.assign({}, newState);
        case SET_NAV_MENUS:
            newState.menus = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;

